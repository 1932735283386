import { FormikErrors } from "formik"
import { focusOnError } from "./focusOnError"
import { isEmpty } from "lodash"

type Props<T=any> = {
  validationResults: FormikErrors<T>
}

export const FocusOnErrorComponent = <T = any>({ validationResults }: Props<T>) => {
  if (!isEmpty(validationResults)) {
    focusOnError(validationResults)
  }

  return null
}
