import {
  Form as BronsonForm,
  FormField as BronsonFormField,
  Checkbox,
  ErrorMessage,
  Fieldset,
  FormHeading,
  Input,
  Layout
} from '@vwfs-bronson/bronson-react';
import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TrackableComponent,
  TrackableComponentType
} from '../../../components/TrackableComponent/TrackableComponent';
import { adobeDataLayer } from '../../../lib/adobe-analytics-data-layer/AdobeDataLayer';

interface FormFieldProps {
  fieldName: string;
  trackingComponent: TrackableComponentType;
  trackingFunction: string;
  label?: string | React.ReactNode;
}

interface CustomFormProps {
  title?: string;
  subtitle: string;
  id: string;
  className: string;
  onSubmit: () => void;
}

type FormProps = CustomFormProps | HTMLFormElement;

export const Form: React.FC<FormProps> = ({ title, subtitle, children, ...props }) => {
  return (
    <BronsonForm floatingLabel id={props.id} className={props.className} onSubmit={props.onSubmit}>
      <Fieldset>
        {title ? (
          <FormHeading description={subtitle}>{title}</FormHeading>
        ) : (
          <small className="c-form-heading__text u-mb">{subtitle}</small>
        )}
        {children}
      </Fieldset>
    </BronsonForm>
  );
};

//type copy from bronson-react/allowedFractions
type numberOfChildrenFraction = '1/1' | '1/2' | '1/3' | '1/4' | '1/5' | '1/6' | '1/12';

export const Row: React.FC = ({ children }) => {
  let numberOfChildren = React.Children.count(children);
  if (numberOfChildren > 6) {
    numberOfChildren = 12;
  }

  return (
    <Fieldset.Row>
      <Layout>
        {React.Children.map(children, (child) => (
          <Layout.Item default={`1/${numberOfChildren}` as numberOfChildrenFraction} xs="1/1">
            {child}
          </Layout.Item>
        ))}
      </Layout>
    </Fieldset.Row>
  );
};

export const FormField: React.FC<FormFieldProps> = ({
  fieldName,
  trackingComponent,
  trackingFunction
}) => {
  const { t } = useTranslation('personal-information');
  const [field, meta] = useField(fieldName);

  return (
    <BronsonFormField
      labelText={t(`form.${fieldName}`)}
      className={classNames('c-form-field--no-floating', {
        'is-error': meta.error && meta.touched
      })}
      type="input"
      errorMessage={meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    >
      <TrackableComponent trackingComponent={trackingComponent} trackingFunction={trackingFunction}>
        <Input
          onChange={field.onChange}
          onBlur={(e: React.FocusEvent) => {
            field.onBlur(e);
            if (meta.error && meta.touched) {
              adobeDataLayer.formFieldErrorPersonalInformation(fieldName);
            }
          }}
          value={field.value}
          name={fieldName}
          id={fieldName}
        />
      </TrackableComponent>
    </BronsonFormField>
  );
};

export const FormFieldCheckbox: React.FC<FormFieldProps> = ({
  fieldName,
  trackingComponent,
  trackingFunction,
  label
}) => {
  const [field, meta] = useField(fieldName);

  return (
    <BronsonFormField
      type="checkbox"
      className={classNames({ 'is-error': meta.error && meta.touched })}
      errorMessage={meta.error && meta.touched ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    >
      <TrackableComponent trackingComponent={trackingComponent} trackingFunction={trackingFunction}>
        <Checkbox
          onChange={field.onChange}
          onBlur={(e: React.FocusEvent) => {
            field.onBlur(e);
            if (meta.error && meta.touched) {
              adobeDataLayer.formFieldErrorPersonalInformation(fieldName);
            }
          }}
          value={field.value}
          name={fieldName}
          id={fieldName}
          checked={field.checked}
        >
          {label}
        </Checkbox>
      </TrackableComponent>
    </BronsonFormField>
  );
};
