export function bronsonIconSwitch(
  audi?: string,
  vw?: string,
  skoda?: string,
  seat?: string,
  blue?: string
): string {
  switch (process.env.REACT_APP_BRAND) {
    case 'audi': {
      return audi ? audi : 'semantic-stop';
    }
    case 'volkswagen': {
      return vw ? vw : 'semantic-stop';
    }
    case 'skoda': {
      return skoda ? skoda : 'semantic-stop';
    }
    case 'seat': {
      return seat ? seat : 'semantic-stop';
    }
    case 'bluelabel': {
      return blue ? blue : 'semantic-stop';
    }
    default: {
      return 'semantic-stop';
    }
  }
}

export function bronsonIconSwitchEmpty(
  audi?: string,
  vw?: string,
  skoda?: string,
  seat?: string,
  blue?: string
): string {
  switch (process.env.REACT_APP_BRAND) {
    case 'audi': {
      return audi ? audi : '';
    }
    case 'volkswagen': {
      return vw ? vw : '';
    }
    case 'skoda': {
      return skoda ? skoda : '';
    }
    case 'seat': {
      return seat ? seat : '';
    }
    case 'bluelabel': {
      return blue ? blue : '';
    }
    default: {
      return '';
    }
  }
}

export const Icons = {
  AdditionalDeposit: 'semantic-fs-payment',
  CarType: 'semantic-performance',
  LandingPage: {
    StepOne: bronsonIconSwitch('apps', 'profile', 'user', 'user'),
    StepTwo: bronsonIconSwitch('lock', 'safety', 'semantic-lock', 'power-lock'),
    StepThree: bronsonIconSwitch('discussion', 'speech-bubbles', 'speech-bubble', 'chat1'),
    StepFour: bronsonIconSwitch('car', 'semantic-car', 'semantic-car', 'semantic-car')
  },

  LandingPageNew: {
    StepOne: 'semantic-user',
    StepTwo: 'semantic-lock',
    StepThree: 'semantic-car'
  },

  // NOTE:
  // For some reason the following icons do not respect the process.env.REACT_APP_BRAND
  // set in the beforeEach of some tests. The only way I was able to make it work is to
  // use an extra function.
  CarOfferPage: {
    StepOne: (): string => {
      return bronsonIconSwitch('voice-call', 'phone', 'phone', 'phone');
    },
    StepTwo: (): string => {
      return 'semantic-calendar';
    }
  },

  FinalEvaluation: (): string => {
    return 'semantic-mobility';
  },

  YourQuote: (): string => {
    return bronsonIconSwitch('editorial', 'manual', 'semantic-offers', 'document-magnifier');
  },

  // Used for places to indicate the transmission type of a vehicle
  Transmission: 'semantic-transmission-manual',

  TransmissionManual: 'semantic-transmission-manual',
  TransmissionAutomatic: 'semantic-transmission-automatic',

  // Used for places to indicate the fuel type of a vehicle
  Fuel: 'semantic-fuel',
  FuelPetrol: 'semantic-petrol',
  FuelDiesel: 'semantic-diesel',
  FuelElectric: 'semantic-electric',
  FuelHybrid: 'semantic-hybrid',
  FuelNaturalGas: 'semantic-fuel-natural-gas',

  // Driving style
  Efficiency: bronsonIconSwitchEmpty(
    'consumption',
    'efficiency',
    'semantic-petrol',
    'petrol-stations',
    'semantic-fuel'
  ),

  Equity: 'semantic-calculator',
  Performance: 'semantic-performance',

  Consumption: bronsonIconSwitch('consumption', 'gas', 'semantic-fuel', 'petrol-stations'),
  Emissions: 'semantic-emission',

  EngineSize: 'semantic-engine',
  EnginePower: 'semantic-performance',

  BodyStyle: 'semantic-car',

  colorOfCar: 'semantic-car-side',

  Payment: 'semantic-fs-payment',

  Calculator: 'semantic-calculator',

  Minus: 'semantic-minus',
  EqualSign: 'semantic-equalsign',

  CurrentCarValue: 'semantic-car-handover',

  PerfectCar: 'semantic-car',

  BestMatch: 'semantic-quality',

  Doors: 'semantic-car-door',

  MonthlyBudget: 'semantic-calendar',

  TestDrive: 'semantic-steering-wheel',
  AnnualMileage: 'semantic-steering-wheel',
  ContractDuration: 'semantic-clock',

  FinanceApplication: 'semantic-user',

  FinanceContractType: 'semantic-brochure',

  CarSearch: bronsonIconSwitch('car-search', 'find-a-car', 'car-magnifier', 'car-search'),

  Drivetrain: 'semantic-four-wheel-drive',
  FrontWheel: 'semantic-front-wheel-drive',

  TowBar: 'semantic-car-tow-bar',

  InteriorColor: bronsonIconSwitch('interior-configuration', 'interior'),
  ExteriorColor: 'semantic-car',

  CarSide: 'car-side'
};
