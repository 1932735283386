/*
 * Adding polyfills for IE11
 * https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
 *
 * Fixing an issue with react-app-polyfills. They have to be included as the first
 * import in your application. With the new jsxRuntime this is not possible because
 * it will always first do import { jsx as _jsx } from 'react/jsx-runtime'
 *
 * So we have to disable this feature for this will. This leads to
 * import React from "react"; being mandatory here!
 */

/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-toastify/dist/ReactToastify.css';

import './i18n';

import React from 'react';
import { render } from 'react-dom';
import { appConfig, getStyles } from './config';

import RenewalApp from './RenewalApp/App';
import { HashRouter as Router } from 'react-router-dom';

function App(): JSX.Element {
  const [appLoaded, setAppLoaded] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      await getStyles(appConfig.brandName).then(() => setAppLoaded(true));
    })();
  }, []);

  if (!appLoaded) return <React.Fragment />;

  return (
    <Router>
      <RenewalApp />
    </Router>
  );
}

render(<App />, document.getElementById('root'));
