import classNames from 'classnames';
import { QuestionItem } from '../../../lib/types/QuestionItem';

type OwnProps = {
  key: string;
  id: string;
  name: string;
  item: QuestionItem<string | number>;
  title: React.ReactElement;
  checked: boolean;
  disabled: boolean;
  displayDescription?: boolean;
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
};

/**
 * Bronson-react doesn't provide any way to use image in <TileSelect /> component.
 * Should use HTML template to provide correct display of .svg images.
 */
export const TileSelectWithImage = ({
  key,
  id,
  name,
  item,
  title,
  checked,
  disabled,
  displayDescription,
  onClick,
  onChange,
  onBlur
}: OwnProps): JSX.Element => {
  return (
    <div className="c-tile-select">
      <label className="c-tile-select__label" htmlFor={name}>
        <input
          key={key}
          className="c-tile-select__input"
          type="checkbox"
          id={id}
          value={item.value}
          name={name}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          checked={checked}
          disabled={disabled}
          aria-disabled={disabled ? 'true' : 'false'}
        />
        <span className="c-tile-select__body">
          <span className="c-tile-select__body-top">
            <img
              src={item.img}
              alt={String(item.value)}
              className={classNames({ 'dr-disabled-img': disabled })}
              height={48}
            />
            <span className="c-tile-select__title u-mt-small">{title}</span>
            {displayDescription && (
              <span className="c-tile-select__text u-mt-xsmall">
                <p className="u-mb-xsmall">{item.description ? item.description : null}</p>
              </span>
            )}
          </span>
        </span>
      </label>
    </div>
  );
};
