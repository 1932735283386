import _axios, { AxiosRequestConfig } from 'axios';

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_SERVER_URL
  // baseURL: "https://api.dev.drmmde.vwfs.io"
};

const axios = _axios.create(axiosConfig);

export default axios;
