export enum Stage {
  dev = 'DEV',
  int = 'INT',
  cons = 'CONS',
  prod = 'PROD'
}

export const getStage = (): Stage => {
  return process.env.REACT_APP_STAGE?.toUpperCase() as Stage || Stage.prod;
};

export const isProd = () => {
  return ![Stage.dev, Stage.int, Stage.cons].includes(getStage());
};
