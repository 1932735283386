import { TransactionStatus } from '@bus/models';
import { AxiosResponse } from 'axios';
import axios from './axios';

export interface TransactionStatusResponseBody {
  status: TransactionStatus;
}

export const getTransactionStatus = async (
  transactionId: string
): Promise<TransactionStatusResponseBody> => {
  const data: AxiosResponse<TransactionStatusResponseBody> =
    await axios.get<TransactionStatusResponseBody>(`/api/transaction/${transactionId}`);

  return data.data;
};
