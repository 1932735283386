import { SelectableTileGroup } from '../../../components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { MatchMakerAnswers } from '../../../../shared/types';
import { fuelList } from '../../helpers/vehicle-helpers';

export function FuelType(): JSX.Element {
  const { t } = useTranslation('match-maker');
  const { values } = useFormikContext<MatchMakerAnswers>();

  return (
    <SelectableTileGroup
      itemList={fuelList(t)}
      itemColumnCount={[4, 6, 12]}
      propName="fuelTypes"
      propObject={values.fuelTypes ?? []}
    />
  );
}
