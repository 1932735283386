import { appConfig } from '../../config';
import axios from './axios';

export const getChiLink = async (legalEntity: string, transactionId: string) => {
  const url = `/api/${appConfig.brandName}/${legalEntity}/${transactionId}/getChiLink`;

  return axios.post(url, { transactionId })
    .then(({ data }) => {
      return data.redirectUrl;
    });
};
