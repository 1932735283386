import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ContentSection, Notification, PageWrap } from '@vwfs-bronson/bronson-react';
import { JOURNEY_STATE, useApplicationState } from '../../App.state';
import { RouterHelpers } from '../../router/router-helpers';

export function TransactionCompletedNotification() {
  const { t } = useTranslation('match-maker');
  const location = useLocation();
  const {
    state: { currentJourneyStatus }
  } = useApplicationState();

  if (
    location.pathname !== RouterHelpers.getConfirmationPagePath() &&
    currentJourneyStatus === JOURNEY_STATE.CONFIRMATION
  ) {
    return (
      <ContentSection>
        <PageWrap>
          <Notification
            visible={true}
            status="warning"
            showCloseButton={false}
            title={t('transaction-completed-notification.title')}
            text={t('transaction-completed-notification.message')}
          />
        </PageWrap>
      </ContentSection>
    );
  } else {
    return <></>;
  }
}
