import React from 'react';
import { Layout, RadioButton } from '@vwfs-bronson/bronson-react';
import { TrackableComponent } from '../../../components';
import { upperFirst } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { MatchMakerAnswers } from '../../../../shared/types';

export const MilesPerYear: React.FC = () => {
  const { t } = useTranslation('match-maker');
  const { setFieldValue, values } = useFormikContext<MatchMakerAnswers>();

  const milesPerYearRadioButtons = [
    {
      inputName: 'milesPerYear',
      inputValue: '10.000',
      checkedIf: values.milesPerYear === 10000,
      onChange: () => setFieldValue('milesPerYear', 10000),
      labelText: t('question-pages.items.mileage.options.one')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '15.000',
      checkedIf: values.milesPerYear === 15000,
      onChange: () => setFieldValue('milesPerYear', 15000),
      labelText: t('question-pages.items.mileage.options.two')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '20.000',
      checkedIf: values.milesPerYear === 20000,
      onChange: () => setFieldValue('milesPerYear', 20000),
      labelText: t('question-pages.items.mileage.options.three')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '25.000',
      checkedIf: values.milesPerYear === 25000,
      onChange: () => setFieldValue('milesPerYear', 25000),
      labelText: t('question-pages.items.mileage.options.four')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '30.000',
      checkedIf: values.milesPerYear === 30000,
      onChange: () => setFieldValue('milesPerYear', 30000),
      labelText: t('question-pages.items.mileage.options.five')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '35.000',
      checkedIf: values.milesPerYear === 35000,
      onChange: () => setFieldValue('milesPerYear', 35000),
      labelText: t('question-pages.items.mileage.options.six')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '40.000',
      checkedIf: values.milesPerYear === 40000,
      onChange: () => setFieldValue('milesPerYear', 40000),
      labelText: t('question-pages.items.mileage.options.seven')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '45.000',
      checkedIf: values.milesPerYear === 45000,
      onChange: () => setFieldValue('milesPerYear', 45000),
      labelText: t('question-pages.items.mileage.options.eight')
    },
    {
      inputName: 'milesPerYear',
      inputValue: '50.000',
      checkedIf: values.milesPerYear === 50000,
      onChange: () => setFieldValue('milesPerYear', 50000),
      labelText: t('question-pages.items.mileage.options.nine')
    }
  ];

  return (
    <Layout data-testid={`RadioLayoutGroup-${milesPerYearRadioButtons[0].inputName}`}>
      {milesPerYearRadioButtons.map((item, index) => (
        <Layout.Item key={`${index}-${item.inputValue}`} default="1/3" xs="1/1">
          <TrackableComponent
            trackingComponent="radiobutton"
            trackingFunction={`radioButton${upperFirst(item.inputName)}${item.inputValue}`}
          >
            <RadioButton
              name={item.inputName}
              value={item.inputValue}
              checked={item.checkedIf}
              onChange={item.onChange}
            >
              {item.labelText}
            </RadioButton>
          </TrackableComponent>
        </Layout.Item>
      ))}
    </Layout>
  );
};
