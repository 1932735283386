import { FormikErrors } from 'formik';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  AdobeDataLayerError,
  AdobeDataLayerEvent,
  AdobeDataLayerObject,
  FinancePlan
} from '../../../custom-types';

import { CustomerContactDetails, Dealer, MatchMakerAnswers } from '../../../shared/types';
import { getStage } from '../helpers/getStage';

const dataTemplate: AdobeDataLayerObject = {
  core: {
    stagingEnvironment: getStage(),
    dataLayerVersion: '2.8',
    pageInfo: {
      pageName: '',
      intendedCustomerDeviceType: 'all',
      version: 'v1.0',
      language: 'de',
      releaseDate: '2021-09-01',
      market: 'DE',
      publisher: 'DU'
    },
    category: {
      primaryCategory: 'Digital renewal',
      siteType: 'standalone',
      maturityLevel: 'Lead',
      contractType: 'Renewal'
    },
    attributes: {
      brand: process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND : 'audi',
      journeyType: 'customer-facing-product-journey',
      viewChange: '',
      context: 'campaign-landingpage',
      transactionID: ''
    }
  },
  error: {
    errorCode: '',
    errorMessage: '',
    errorCausingURL: ''
  },
  form: {
    name: '',
    type: ''
  },

  dealerData: {
    companyId: '',
    companyName: '',
    address: {
      street: '',
      zipCode: '',
      city: ''
    },
    regionId: ''
  },
  video: {},
  design: {
    browserResolutionBreakpoint: '960'
  },
  customerData: {
    loggedInUserGroup: 'private',
    loginStatus: false,
    address: {
      zipCode: ''
    }
  },
  filter: [],
  dataPrivacyStatement: {},
  event: []
};

class AdobeDataLayerClass {
  private _baseData = _cloneDeep(dataTemplate);

  private sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  private cloneBaseData() {
    return _cloneDeep(this._baseData);
  }

  private track(
    trackingData: AdobeDataLayerObject,
    interactionType: 'success' | 'interaction' | 'page'
  ) {
    try {
      (window as any)['du_digitalData'] = trackingData;
      window._satellite.track(interactionType);
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(`message: ${(e as any).message}, stack: ${(e as any).stack}`);
      }
    }
  }

  private getThemeBasedOnPercentage(
    percentage: number
  ):
    | 'Condition & Body & Transmission'
    | 'Fuel & Performance'
    | 'Special equipment & Extra services'
    | 'Financial' {
    switch (percentage) {
      case 1:
        return 'Condition & Body & Transmission';
      case 2:
        return 'Fuel & Performance';
      case 3:
        return 'Special equipment & Extra services';
      case 4:
        return 'Financial';

      default:
        return 'Condition & Body & Transmission';
    }
  }

  private getErrorArray(errors: FormikErrors<MatchMakerAnswers>): string[] {
    const errorArray: string[] = [];

    if (errors.additionalDeposit) errorArray.push('additionalDeposit');
    if (errors.additionalServices) errorArray.push('additionalServices');
    if (errors.condition) errorArray.push('condition');
    if (errors.expectedDuration) errorArray.push('expectedDuration');
    if (errors.expectedMonthlyPayment) errorArray.push('expectedMonthlyPayment');
    if (errors.financePlan) errorArray.push('financePlan');
    if (errors.fuelTypes) errorArray.push('fuelTypes');
    if (errors.maxPower || errors.minPower) errorArray.push('power');
    if (errors.milesPerYear) errorArray.push('milesPerYear');
    if (errors.modelOfCar) errorArray.push('modelOfCar');
    if (errors.specialEquipments) errorArray.push('specialEquipments');
    if (errors.typeOfCar) errorArray.push('typeOfCar');
    if (errors.typeOfGears) errorArray.push('typeOfGears');

    return errorArray;
  }

  public setBaseDataMatchMakerAnswers(mmAnswers: MatchMakerAnswers) {
    this._baseData.filter = [];
    mmAnswers.typeOfCar &&
      this._baseData.filter.push({
        filterName: 'Body type',
        filterValue: mmAnswers.typeOfCar
      });
    mmAnswers.modelOfCar &&
      this._baseData.filter.push({
        filterName: 'Car Model',
        filterValue: mmAnswers.modelOfCar
      });
    mmAnswers.typeOfGears &&
      this._baseData.filter.push({
        filterName: 'Transmission',
        filterValue: mmAnswers.typeOfGears
      });
    mmAnswers.fuelTypes &&
      this._baseData.filter.push({
        filterName: 'Fuel type',
        filterValue: mmAnswers.fuelTypes
      });
    mmAnswers.expectedMonthlyPayment &&
      this._baseData.filter.push({
        filterName: 'Rate',
        filterValue: [`${mmAnswers.expectedMonthlyPayment} EUR`]
      });
    mmAnswers.additionalDeposit &&
      this._baseData.filter.push({
        filterName: 'Downpayment',
        filterValue: [`${mmAnswers.additionalDeposit} EUR`]
      });
    mmAnswers.milesPerYear &&
      this._baseData.filter.push({
        filterName: 'Yearly mileage',
        filterValue: [mmAnswers.milesPerYear.toString()]
      });
    mmAnswers.expectedDuration &&
      this._baseData.filter.push({
        filterName: 'Contract duration',
        filterValue: [mmAnswers.expectedDuration.toString()]
      });
    mmAnswers.maxPower &&
      mmAnswers.minPower &&
      this._baseData.filter.push({
        filterName: 'Power',
        filterValue: [`from ${mmAnswers.minPower} to ${mmAnswers.maxPower}`]
      });
    mmAnswers.condition &&
      this._baseData.filter.push({
        filterName: 'Condition',
        filterValue: mmAnswers.condition
      });
    mmAnswers.financePlan &&
      this._baseData.filter.push({
        filterName: 'Finance plan',
        filterValue: [`${mmAnswers.financePlan}`]
      });
  }

  public setBaseDealerData(dealer: Dealer, isPreselected: boolean) {
    this._baseData.dealerData.companyId = dealer.id;
    this._baseData.dealerData.companyName = dealer.name;
    this._baseData.dealerData.regionId = dealer.regionId;
    this._baseData.dealerData.address.street = dealer.street;
    this._baseData.dealerData.address.zipCode = dealer.zip;
    this._baseData.dealerData.address.city = dealer.city;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        linkInformation: isPreselected
          ? 'Move on with current partner'
          : 'Move on with partner from result list',
        eventAction: 'Success',
        eventType: 'interAction'
      }
    };

    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Dealer selection';
    trackingData.core.attributes.viewChange = 'Dealer selection';

    trackingData.event.push(event);

    this.track(trackingData, 'interaction');
  }

  public selectDealer(dealer: Dealer, isPreselected: boolean) {
    this.setBaseDealerData(dealer, isPreselected);
  }

  public setBaseDataPersonalDetails(contactDetails: CustomerContactDetails) {}

  public interactionEntryPoint() {
    const trackingData = this.cloneBaseData();
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('transactionId')) {
      trackingData.core.attributes.transactionID = searchParams.get('transactionId') ?? '';
    }

    this.track(trackingData, 'interaction');
  }

  public async pageViewMatchMakerQuestionPage(
    number: number,
    themes:
      | 'Vehicle preferences'
      | 'Financial preferences'
      | 'Vehicle additional preferences'
      | 'Financial additional preferences'
  ) {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Match maker';
    trackingData.core.attributes.viewChange = `Question page ${number}: ${themes}`;

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, 'page');
  }

  public async formFieldErrorMatchMakerQuestionPage(
    percentage: number,
    errors: FormikErrors<MatchMakerAnswers>
  ) {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    const themes = this.getThemeBasedOnPercentage(percentage);
    const errorFields = this.getErrorArray(errors);

    trackingData.core.pageInfo.pageName = 'Match maker';
    trackingData.core.attributes.viewChange = `Question page ${percentage}: ${themes}`;

    trackingData.form.errorFields = errorFields;

    const interactionEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Field error',
        eventType: 'Form',
        linkInformation: 'Form field error'
      }
    };

    trackingData.event.push(interactionEvent);

    this.track(trackingData, 'interaction');
  }

  public async interactionVideoLinkClicked(id: string, product: FinancePlan) {
    this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.video.videoID = id;
    trackingData.video.videoName = product.toString();

    trackingData.event.push({
      eventInfo: {
        linkInformation: 'Video click',
        eventAction: 'Success',
        eventType: 'interAction'
      }
    });

    this.track(trackingData, 'interaction');
  }

  public async pageViewDealerSelectionInitial() {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Dealer selection';
    trackingData.core.attributes.viewChange = 'Input geo data';

    trackingData.form.type = 'Geographic data';
    trackingData.form.name = 'Personal location';

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };

    trackingData.event.push(viewEvent);

    this.track(trackingData, 'page');
  }

  public async pageViewDealerSelectionPostSearch() {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Dealer selection';
    trackingData.core.attributes.viewChange = 'Select dealer';

    trackingData.form.type = 'Dealer selection';
    trackingData.form.name = 'Dealer selection';

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };

    trackingData.event.push(viewEvent);

    this.track(trackingData, 'page');
  }

  public async pageViewPersonalInformation() {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Personal data';
    trackingData.core.attributes.viewChange = 'Personal data';

    trackingData.form.type = 'Personal data';
    trackingData.form.name = 'Personal data';

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };

    trackingData.event.push(viewEvent);

    this.track(trackingData, 'page');
  }

  public formFieldErrorPersonalInformation(errorField: string) {
    const trackingData = this.cloneBaseData();

    trackingData.form.errorFields = [errorField];

    const errorEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Field error',
        eventType: 'Form'
      }
    };
    trackingData.event.push(errorEvent);

    this.track(trackingData, 'interaction');
  }

  public formSubmitValidationErrorPersonalInformation(errorFields: string[]) {
    const trackingData = this.cloneBaseData();

    trackingData.form.errorFields = errorFields;

    const interactionEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Field error',
        eventType: 'Form',
        linkInformation: 'Form field error'
      }
    };
    trackingData.event.push(interactionEvent);

    this.track(trackingData, 'interaction');
  }

  public async pageViewMatchMakerSummary() {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Match maker summary';
    trackingData.core.attributes.viewChange = 'Match maker summary';

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, 'page');
  }

  public interactionMatchMakerSummaryEditButtonClicked(
    sectionName:
      | 'Condition & Body & Transmission'
      | 'Fuel & Performance'
      | 'Special equipment & Extra services'
      | 'Financial'
      | 'Dealer Selection'
      | 'Customer Information'
  ) {
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Match maker summary';
    trackingData.core.attributes.viewChange = 'Match maker summary';

    trackingData.customerData.loginStatus = true;

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'interAction',
        linkInformation: `Edit ${sectionName}`
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, 'interaction');
  }

  public async pageViewConfirmationPage() {
    await this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Next steps';
    trackingData.core.attributes.viewChange = 'Next steps';

    const event: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };

    trackingData.event.push(event);

    this.track(trackingData, 'page');
  }

  public async confirmationCHIInteraction() {
    this.sleep(1000);
    const trackingData = this.cloneBaseData();
    trackingData.core.pageInfo.pageName = 'Next steps';
    trackingData.core.attributes.viewChange = 'Next steps';

    trackingData.event.push({
      eventInfo: {
        linkInformation: 'Determine customer happiness index',
        eventAction: 'Success',
        eventType: 'interAction'
      }
    });

    this.track(trackingData, 'interaction');
  }

  public async pageViewErrorPage(error: AdobeDataLayerError) {
    this.sleep(1000);
    const trackingData = this.cloneBaseData();

    if (trackingData.core.pageInfo.pageName === 'Error page') {
      return;
    }
    trackingData.core.pageInfo.pageName = 'Error page';
    trackingData.core.attributes.viewChange = `Error page - ${error.errorMessage}`;
    trackingData.error = error;

    const viewEvent: AdobeDataLayerEvent = {
      eventInfo: {
        eventAction: 'Success',
        eventType: 'pageView'
      }
    };
    const errorEvent: AdobeDataLayerEvent = {
      eventInfo: { eventAction: 'Technical error', eventType: 'Error' }
    };
    if (error.errorCode === '404') {
      errorEvent.eventInfo = {
        eventAction: 'Page not found',
        eventType: 'Error'
      };
    }

    trackingData.event.push(viewEvent);
    trackingData.event.push(errorEvent);

    this.track(trackingData, 'page');
  }

  public async interactionLogoClicked() {
    this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.core.pageInfo.pageName = 'Match maker';
    trackingData.event.push({
      eventInfo: {
        linkInformation: 'Header brand logo',
        eventAction: 'Success',
        eventType: 'interAction'
      }
    });

    this.track(trackingData, 'interaction');
  }

  public async interactionWizardLinkClicked(
    linkName: 'Match Maker' | 'Financing' | 'Dealer Selection' | 'Summary' | 'Confirmation'
  ) {
    this.sleep(1000);
    const trackingData = this.cloneBaseData();

    trackingData.event.push({
      eventInfo: {
        linkInformation: `Header go to ${linkName}`,
        eventAction: 'Success',
        eventType: 'interAction'
      }
    });

    this.track(trackingData, 'interaction');
  }
}

const adobeDataLayer = new AdobeDataLayerClass();

export { adobeDataLayer };
