import React from 'react';
import { Layout, RadioButton } from '@vwfs-bronson/bronson-react';
import { TrackableComponent } from '../../../components';
import { upperFirst } from 'lodash';
import { useFormikContext } from 'formik';
import { MatchMakerAnswers } from '../../../../shared/types';
import { AdditionalDepositInput } from './AdditionalDepositInput';

export const AdditionalDepositInfo: React.FC = () => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<MatchMakerAnswers>();

  const onChange = (value: boolean) => {
    return () => {
      if (value === false) {
        setFieldValue('additionalDeposit', 0);
        setFieldTouched('additionalDeposit', false);
      }
      setFieldValue('additionalDepositRadio', value);
      setFieldTouched('additionalDepositRadio');
    };
  };

  const AdditionalDepositRadioButtons = [
    {
      inputName: 'additionalDepositRadio',
      inputValue: false,
      checkedIf: !values.additionalDepositRadio,
      onChange: onChange(false),
      labelText: 'Nein'
    },
    {
      inputName: 'additionalDepositRadio',
      inputValue: true,
      checkedIf: values.additionalDepositRadio,
      onChange: onChange(true),
      labelText: 'Ja'
    }
  ];

  return (
    <Layout data-testid={`RadioLayoutGroup-${AdditionalDepositRadioButtons[0].inputName}`}>
      {AdditionalDepositRadioButtons.map((item, index) => (
        <Layout.Item key={`${index}-${item.inputValue}`} default="1/3" xs="1/1">
          <TrackableComponent
            trackingComponent="radiobutton"
            trackingFunction={`radioButton${upperFirst(item.inputName)}${item.inputValue}`}
          >
            <RadioButton
              name={item.inputName}
              value={item.inputValue.toString()}
              checked={item.checkedIf}
              onChange={item.onChange}
            >
              {item.labelText}
            </RadioButton>
          </TrackableComponent>
        </Layout.Item>
      ))}
      <Layout.Item default="1/1">
        {values.additionalDepositRadio === true && <AdditionalDepositInput />}
      </Layout.Item>
    </Layout>
  );
};
