import { Notification, PageWrap } from '@vwfs-bronson/bronson-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { adobeDataLayer } from '../../adobe-analytics-data-layer/AdobeDataLayer';

interface OwnProps {
  errorMessage?: string;
}

export const DefaultError: React.FunctionComponent<OwnProps> = ({ errorMessage }) => {
  const { t } = useTranslation('shared');
  const message: string = errorMessage || t('connection-error');

  useEffect(() => {
    adobeDataLayer.pageViewErrorPage({
      errorCausingURL: window.location.href,
      errorCode: '449',
      errorMessage: message
    });
  });

  return (
    <PageWrap>
      <Notification title="Verbindungsproblem" status="error" visible showCloseButton={false}>
        {message}
      </Notification>
    </PageWrap>
  );
};
