import ImgNotFound from '../../../resources/assets/images/img_not_found.jpg';

export function imgSwitch(
  audi?: string,
  vw?: string,
  skoda?: string,
  seat?: string,
  blue?: string
): string {
  switch (process.env.REACT_APP_BRAND) {
    case 'audi': {
      return audi ? audi : ImgNotFound;
    }
    case 'volkswagen': {
      return vw ? vw : ImgNotFound;
    }
    case 'skoda': {
      return skoda ? skoda : ImgNotFound;
    }
    case 'seat': {
      return seat ? seat : ImgNotFound;
    }
    case 'bluelabel': {
      return blue ? blue : ImgNotFound;
    }
    default: {
      return ImgNotFound;
    }
  }
}
