import { SelectableTileGroup } from '../../../components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { MatchMakerAnswers } from '../../../../shared/types';
import { conditionList } from '../../helpers/vehicle-helpers';

export function VehicleCondition(): JSX.Element {
  const { t } = useTranslation('match-maker');
  const { values } = useFormikContext<MatchMakerAnswers>();

  return (
    <SelectableTileGroup
      itemList={conditionList(t)}
      itemColumnCount={[6, 6, 12]}
      propName="condition"
      propObject={values.condition ?? []}
    />
  );
}
