import { TrackableComponent } from '../../../components/TrackableComponent/TrackableComponent';
import { TFunction } from 'i18next';
import React from 'react';

interface OwnProps {
  showMoreButtonVisible: boolean;
  loadMore: () => void;
  t: TFunction;
}

export const LoadMoreButton: React.FC<OwnProps> = ({ showMoreButtonVisible, loadMore, t }) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const loadMoreAndBlur = (): void => {
    if (buttonRef.current) {
      loadMore();
      buttonRef.current.blur();
    }
  };

  if (!showMoreButtonVisible) {
    return null;
  }

  return (
    <div className="u-pt-large u-text-center">
      <TrackableComponent trackingComponent="button" trackingFunction="buttonShowFurtherDealers">
        <button ref={buttonRef} className={'c-btn c-btn--secondary'} onClick={loadMoreAndBlur}>
          <span className="c-btn__text">{t('results.show-more-button')}</span>
          <i className={'c-btn__icon c-icon  c-icon--[arrow-down]'} />
        </button>
      </TrackableComponent>
    </div>
  );
};
