import React, { useState, useCallback } from 'react';
import {
  Button,
  ButtonContainer,
  Callout,
  Paragraph,
  Spinner,
  ContentSection
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { getChiLink } from '../../api/getChiLink';
import { useApplicationState } from '../../App.state';
import { isProd } from '../../lib/helpers/getStage';
import { appConfig } from '../../../config';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';

const chiImageSrc = (() => {
  const base = process.env.PUBLIC_URL + '/images/CHI--';
  switch (appConfig.brandName) {
    case 'audi':
      return base + 'audi.png';
    case 'cupra':
      return base + 'cupra.png';
    case 'seat':
      return base + 'seat.png';
    case 'vw':
      return base + 'vw6.png';
    case 'vwn':
      return base + 'vw6.png';
  }
})();

export const CustomerHappiness = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('chi');
  const { state } = useApplicationState();

  const { transactionId, legalEntity } = state;

  const goToQuestionnaire = useCallback(async () => {
    setIsLoading(true);

    try {
      let redirectUrl = '';

      if (isProd()) {
        redirectUrl = await getChiLink(legalEntity, transactionId);
      } else {
        redirectUrl = await getChiLink(legalEntity, transactionId).catch(() => {
          console.error('Failed to get CHI link. Fallback to example URL for the sake of testing.');
          return 'https://www.example.com';
        });
      }

      if (redirectUrl) {
        window.open(redirectUrl, '_blank', 'noopener');
      }
    } finally {
      adobeDataLayer.confirmationCHIInteraction();
      setIsLoading(false);
    }
  }, [transactionId]);

  const calloutTitle = t('chi:beforeSubmit:title');
  const calloutMainText1 = t('chi:beforeSubmit:textLine1');
  const calloutMainText2 = t('chi:beforeSubmit:textLine2');

  const renderChiSubmissionUi = () => {
    return (
      <>
        <ButtonContainer className="u-mt" center>
          <Button onClick={goToQuestionnaire}>
            {isLoading ? <Spinner section center small /> : t('chi:button')}
          </Button>
        </ButtonContainer>
      </>
    );
  };

  return (
    <>
      <ContentSection>
        <div className="o-page-wrap o-page-wrap--xxsmall">
          <div className="o-component-wrapper">
            <Callout
              testId="customerHappinessWidget"
              media={{
                alt: 'Customer Happiness Index icon',
                sizes: '20vw',
                src: chiImageSrc,
                srcSet: [chiImageSrc + ' 512w']
              }}
              title={calloutTitle}
            >
              <Paragraph>
                {calloutMainText1}
                <br />
                {calloutMainText2}
              </Paragraph>
              {renderChiSubmissionUi()}
            </Callout>
          </div>
        </div>
      </ContentSection>

      <ContentSection pageWrap>
        <Paragraph
          className="c-footnotes"
          dangerouslySetInnerHTML={{ __html: `*  ${t('chi:legalNote')}` }}
        />
      </ContentSection>
    </>
  );
};
