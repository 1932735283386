import { useState } from 'react';

import { Button, ButtonContainer, SectionHeading } from '@vwfs-bronson/bronson-react';
import classNames from 'classnames';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TrackableComponent } from '../../components';

import { isEmpty } from 'lodash';
import { MATCH_MAKER_FORM_ID, focusOnError } from '../../../shared/focusOnError';
import { Step } from '../../../shared/interface';
import { MatchMakerAnswers } from '../../../shared/types';
import { FlowType, JOURNEY_STATE, useApplicationState } from '../../App.state';
import { submitAnswers } from '../../api/submitAnswers';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { loadingOrError } from '../../lib/ui/Loaders/GraphqlLoadingUtility';
import { RouterHelpers } from '../../router/router-helpers';

const TOTAL_QUESTION_PAGES = 4;

interface OwnProps {
  currentPercentage?: number;
  nextPage: string;
  previousPage?: string;
  skipTo?: {
    page: string;
    targetJourneyState: JOURNEY_STATE;
    skippedPagesData?: Partial<MatchMakerAnswers>;
  };
  onGoToNextPage?: () => void;
  onGoToPreviousPage?: () => void;
  children: ((formikProps: FormikProps<MatchMakerAnswers>) => JSX.Element) | JSX.Element;
  isEditMode?: boolean;
  isCompleted?: boolean;
  pageTitle: string;
  validate?: (values: MatchMakerAnswers) => FormikErrors<MatchMakerAnswers>;
  pageWrapSmall?: boolean;
  currentStep: Step;
}
function MatchMakerQuestionPage(props: OwnProps): JSX.Element {
  const {
    isEditMode = false,
    isCompleted = false,
    currentPercentage = 0,
    pageTitle,
    validate,
    nextPage,
    previousPage,
    skipTo,
    onGoToNextPage,
    pageWrapSmall = true,
    currentStep
  } = props;

  const { t } = useTranslation('shared');
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<Error>();
  const { state, dispatch } = useApplicationState();
  const willSkipNow = isSkipping && !!skipTo;

  if (isLoading || submitError) return loadingOrError(isLoading, submitError);

  const { matchMakerAnswers, dealer, transactionId, customerInformation } = state;

  const goToPage = (path: string) => {
    navigate(path);
  };

  const determineUserSelectedFlow = (): FlowType => {
    // We are on skiping step
    if (skipTo) {
      // User selected long path previously, but changed mind and selected short path
      if (state.flowType === FlowType.LONG && willSkipNow) {
        return FlowType.HYBRID;
      }

      // User selected short path, but changed mind and selected long path
      if (state.flowType == FlowType.SHORT && !willSkipNow) {
        return FlowType.LONG;
      }

      // User was on hybrid path, stays on hybrid
      if (state.flowType === FlowType.HYBRID) {
        return FlowType.HYBRID;
      }

      if (willSkipNow) {
        return FlowType.SHORT;
      }

      if (!willSkipNow) {
        return FlowType.LONG;
      }
    }

    return FlowType.UNSELECTED;
  };

  return (
    <>
      <SectionHeading level="1" testId={`MatchMakerQuestionWrapper-${currentPercentage}`}>
        {pageTitle}
      </SectionHeading>
      {!isEditMode && (
        <ProgressBar
          value={`${(100 / TOTAL_QUESTION_PAGES) * currentPercentage}`}
          currentStep={currentPercentage}
          maxSteps={TOTAL_QUESTION_PAGES}
        />
      )}
      <Formik
        initialValues={matchMakerAnswers ?? ({} as MatchMakerAnswers)}
        onSubmit={(values, { setValues }) => {
          setIsLoading(true);
          let newAnswers = {
            ...values,
            isCompleted: isEditMode ? true : isCompleted
          };

          const userSelectedFlow: FlowType = determineUserSelectedFlow();

          if (willSkipNow && !!skipTo.skippedPagesData && userSelectedFlow === FlowType.SHORT) {
            setValues(skipTo.skippedPagesData);
            newAnswers = { ...newAnswers, ...skipTo.skippedPagesData };
          }

          submitAnswers({
            transactionId,
            matchMakerAnswers: newAnswers,
            selectedDealer: dealer.id,
            contactDetails: customerInformation,
            currentStep,
            isEditMode: isEditMode
          })
            .then((data) => {
              dispatch({
                type: 'updateState',
                state: {
                  ...state,
                  transactionId: data,
                  matchMakerAnswers: newAnswers,
                  ...(!!skipTo && { flowType: userSelectedFlow })
                }
              });
              setIsLoading(false);
              adobeDataLayer.setBaseDataMatchMakerAnswers(values);

              if (onGoToNextPage) onGoToNextPage();

              isEditMode ? goToPage(RouterHelpers.getSummaryPagePath()) : goToPage(nextPage);

              if (willSkipNow) {
                goToPage(skipTo.page);

                dispatch({
                  type: 'updateJourneyStatus',
                  journeyStatus: skipTo.targetJourneyState,
                  isEditMode: false
                });
              }
            })
            .catch((e) => {
              setSubmitError(e);
              setIsLoading(false);
              setIsSkipping(false);
            });
        }}
        validateOnBlur={true}
        validate={(values) => {
          if (validate) {
            const result = validate(values);

            if (isSubmitting && !isEmpty(result)) {
              adobeDataLayer.formFieldErrorMatchMakerQuestionPage(currentPercentage, result);
              focusOnError(result);
            }

            setIsSubmitting(false);

            return result;
          }
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit} id={MATCH_MAKER_FORM_ID}>
            <div
              className={classNames('o-page-wrap', {
                'o-page-wrap--small': pageWrapSmall
              })}
            >
              <div className="o-component-wrapper">
                {props.children instanceof Function && props.children(formikProps)}
                {!(props.children instanceof Function) && props.children}
              </div>
              <div className="o-component-wrapper u-pt">
                <ButtonContainer nav>
                  {isEditMode && (
                    <>
                      <Button type="submit">
                        {t('match-maker:question-pages.save-continue-button')}
                      </Button>
                    </>
                  )}
                  {!isEditMode && (
                    <>
                      {previousPage !== undefined ? (
                        <TrackableComponent
                          trackingComponent="button"
                          trackingFunction="buttonBack"
                        >
                          <Button
                            type="button"
                            secondary
                            onClick={() => {
                              goToPage(previousPage);
                            }}
                            inContainer
                            className="u-1/6 u-1/3@m u-1/1@xs"
                          >
                            {t('back-button')}
                          </Button>
                        </TrackableComponent>
                      ) : null}

                      <>
                        <TrackableComponent
                          trackingComponent="button"
                          trackingFunction="buttonNext"
                        >
                          <Button
                            type="submit"
                            secondary={skipTo ? true : undefined}
                            inContainer
                            className="u-1/6 u-1/3@m u-1/1@xs"
                            onClick={() => setIsSubmitting(true)}
                          >
                            {skipTo
                              ? t('match-maker:question-pages.next-button-skip-enabled')
                              : t('match-maker:question-pages.next-button')}
                          </Button>
                        </TrackableComponent>
                      </>

                      {skipTo && (
                        <>
                          <TrackableComponent
                            trackingComponent="button"
                            trackingFunction="buttonSkip"
                          >
                            <Button
                              type="submit"
                              inContainer
                              className="u-1/6 u-1/3@m u-1/1@xs"
                              onClick={() => {
                                setIsSubmitting(true);
                                setIsSkipping(true);
                              }}
                            >
                              {t('match-maker:question-pages.skip-steps-button')}
                            </Button>
                          </TrackableComponent>
                        </>
                      )}
                    </>
                  )}
                </ButtonContainer>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default MatchMakerQuestionPage;
