import React from 'react';

export type TrackableComponentType =
  | 'button'
  | 'link'
  | 'inputbox'
  | 'checkbox'
  | 'radiobutton'
  | 'tooltip'
  | 'expand'
  | 'dropdown'
  | 'CustomRangeSlider';

interface TrackableComponentProps {
  trackingComponent: TrackableComponentType;
  trackingFunction: string;
}

export const TrackableComponent: React.FunctionComponent<TrackableComponentProps> = ({
  trackingFunction,
  trackingComponent,
  children
}): JSX.Element => {
  const child = React.Children.only(children);
  let props = {};

  // We only add the additional props when there is a trackingFunction
  if (trackingFunction) {
    props = {
      'data-tr-component': trackingComponent,
      'data-tr-function': trackingFunction
    };
  }

  return React.cloneElement(child as React.ReactElement, props);
};
