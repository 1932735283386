// import { FinancePlan } from '../custom-types';

export interface Dealer {
  name: string;
  street: string;
  zip: string;
  city: string;
  phone: string;
  id: string;
  email: string;
  houseNumber: string;
  regionId: string;
}

export enum IFuelType {
  Unknown = '_Unknown',
  Petrol = 'Petrol',
  Diesel = 'Diesel',
  Electric = 'Electric',
  Hybrid = 'Hybrid',
  NaturalGas = 'NaturalGas'
}

export enum ITransmissionType {
  Unknown = '_Unknown',
  Manual = 'Manual',
  Automatic = 'Automatic'
}

export enum IMatchType {
  Best = 'Best',
  Great = 'Great',
  Good = 'Good',
  Fair = 'Fair',
  NoMatch = 'NoMatch'
}

export enum ICarSearchType {
  BYTYPE = 'ByType',
  BYMODEL = 'ByModel'
}

export interface MatchMakerAnswers {
  minPower?: number;
  maxPower?: number;
  condition?: string[]; // NEW or USED
  typeOfCar?: string[];
  modelOfCar?: string[];
  typeOfGears?: ITransmissionType[];
  fuelTypes?: IFuelType[];
  expectedMonthlyPayment?: number;
  expectedDuration?: number;
  milesPerYear?: number;
  isCompleted?: boolean;
  additionalDeposit?: number;
  additionalDepositRadio?: boolean;
  carSearchType?: ICarSearchType;
  financePlan?: any;
  specialEquipments?: string[];
  additionalServices?: string[];
}

export interface CustomerContactDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  contactPreference: ContactOption;
}

export type LocalBrands = 'audi' | 'seat' | 'cupra' | 'vw' | 'vwn';

export enum ContactOption {
  Phone = 'Phone',
  Email = 'Email'
}

export type LocalLanguages = 'de-DE';
