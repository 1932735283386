import { useFormikContext } from 'formik';
import React from 'react';

import { RangeSlider } from './components/RangeSlider';
import { MatchMakerAnswers } from '../../../../shared/types';

export const Power: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<MatchMakerAnswers>();

  return <RangeSlider values={values} setFieldValue={setFieldValue} />;
};
