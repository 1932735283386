import {
  BackToTop,
  Footer as BronsonReactFooter
} from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation('scaffolding');
  return (
    <>
      <BackToTop>{t('footer.back-to-top')}</BackToTop>
      <BronsonReactFooter>
        <BronsonReactFooter.ModuleMetaNav copyrightText={t('footer.copyright')}>
          <BronsonReactFooter.ModuleMetaNav.Item
            href={t('footer.link-legal-notice-to')}
            normalLink={true}
            linkProps={{ target: '_blank', rel: 'noopener noreferrer' }}
          >
            {t('footer.link-legal-notice')}
          </BronsonReactFooter.ModuleMetaNav.Item>

          <BronsonReactFooter.ModuleMetaNav.Item
            href={t('footer.link-privacy-policy-to')}
            normalLink={true}
            linkProps={{ target: '_blank', rel: 'noopener noreferrer' }}
          >
            {t('footer.link-privacy-policy')}
          </BronsonReactFooter.ModuleMetaNav.Item>

          <BronsonReactFooter.ModuleMetaNav.Item
            href={t('footer.link-cookie-policy-to')}
            normalLink={true}
            linkProps={{ target: '_blank', rel: 'noopener noreferrer' }}
          >
            {t('footer.link-cookie-policy')}
          </BronsonReactFooter.ModuleMetaNav.Item>

          <BronsonReactFooter.ModuleMetaNav.Item
            href={t('footer.link-oss-notice-to')}
            normalLink={true}
            linkProps={{ target: '_blank', rel: 'noopener noreferrer' }}
          >
            {t('footer.link-oss-notice')}
          </BronsonReactFooter.ModuleMetaNav.Item>
        </BronsonReactFooter.ModuleMetaNav>
      </BronsonReactFooter>
    </>
  );
};

export default Footer;
