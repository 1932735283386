import React, { useEffect } from 'react';

import { Step } from '../../../shared/interface';
import {
  MM3Fields,
  getFieldValidators
} from '../../../shared/validation-rules/match-maker-validator';
import { JOURNEY_STATE, useApplicationState } from '../../App.state';
import { Question } from '../../components/Questions';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import {
  EquipmentAndServices,
  Item
} from '../../lib/components/MatchMakerAnswersEditComponents/EquipmentAndServices';
import { Power } from '../../lib/components/MatchMakerAnswersEditComponents/Power';
import { TransmissionType } from '../../lib/components/MatchMakerAnswersEditComponents/TransmissionType';
import { mapOptionsToItems } from '../../lib/helpers/map-options-to-items-helpers';
import { RouterHelpers } from '../../router/router-helpers';
import { useCustomTranslation } from '../../utils/translations';
import { genericValidator } from '../MatchMakerHelper';
import MatchMakerQuestionWrapper from './MatchMakerQuestionWrapper';

interface OwnProps {
  isEditMode?: boolean;
}

export const MatchMakerQuestion3: React.FC<OwnProps> = ({ isEditMode }) => {
  const { t } = useCustomTranslation('match-maker');
  const { dispatch } = useApplicationState();

  useEffect(() => {
    adobeDataLayer.pageViewMatchMakerQuestionPage(3, 'Vehicle additional preferences');
  }, []);

  const equipmentItems: Item[] = mapOptionsToItems(
    t,
    'question-pages.items.special-equipments.options'
  );

  return (
    <MatchMakerQuestionWrapper
      currentStep={Step.MM3}
      pageTitle={t('question-pages.question-page-three.title')}
      isEditMode={isEditMode}
      currentPercentage={3}
      nextPage={RouterHelpers.getMatchMakerQuestionPage4Path()}
      previousPage={RouterHelpers.getMatchMakerQuestionPage2Path()}
      validate={(values) => {
        const validationResult = genericValidator(
          getFieldValidators(t.sieToDu, MM3Fields, values),
          values
        );

        return validationResult;
      }}
      isCompleted={true}
      onGoToNextPage={() =>
        dispatch({
          type: 'updateJourneyStatus',
          journeyStatus: JOURNEY_STATE.FINANCING,
          isEditMode: false
        })
      }
    >
      {() => (
        <>
          <Question heading={t('question-pages.items.gears.label')}>
            <TransmissionType />
          </Question>

          <Question heading={t('question-pages.items.power.label')}>
            <Power />
          </Question>

          <Question heading={t('question-pages.items.special-equipments.label')}>
            <EquipmentAndServices items={equipmentItems} fieldName="specialEquipments" />
          </Question>
        </>
      )}
    </MatchMakerQuestionWrapper>
  );
};
