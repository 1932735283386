import { FormikErrors } from "formik";

export const MATCH_MAKER_FORM_ID = 'match-maker-form';
const inputTypesToFocus = ['text'];

export const focusOnError = <T = any>(validationResults: FormikErrors<T>) => {
  const inputs = document.querySelectorAll<HTMLInputElement>(`#${MATCH_MAKER_FORM_ID} input[id][name]`);
  const failingFieldsNames = Object.keys(validationResults);

  const firstFailingInput = Object.entries(inputs).find(([, element]) => {
    return failingFieldsNames.some(fieldName => {
      return element.getAttribute('id')?.startsWith(fieldName);
    });
  })?.[1];

  if (firstFailingInput && inputTypesToFocus.includes(firstFailingInput?.type)) {
    firstFailingInput?.focus();
  }

  firstFailingInput?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
}
