/* eslint-disable indent */
import React, { HTMLAttributes, useEffect } from 'react';
import { TileSelectGroup, TileSelect, Layout } from '@vwfs-bronson/bronson-react';
import { useFormikContext } from 'formik';
import { FieldError, TrackableComponent } from '..';
import _ from 'lodash';
import { QuestionItem } from '../../lib/types/QuestionItem';
import { bronsonIconSwitchEmpty } from '../../lib/helpers/icon-helpers';
import { TileSelectWithImage } from './items/TileSelectWithImage';
import { MatchMakerAnswers } from '../../../shared/types';

type OwnProps = {
  title?: string;
  itemColumnCount?: number[];
  itemList: QuestionItem<string | number>[];
  propName: keyof MatchMakerAnswers;
  propObject: object;
  maxChoices?: number;
  maxChoicesWarning?: string;
  displayDescription?: boolean;
  preserveValues?: boolean;
  showErros?: boolean;
  onPostChange?: () => void;
} & HTMLAttributes<HTMLDivElement>;

/** The array itemColumnCount takes two numbers:
 *  - the first to assign the grid for desktop
 *  - the second one to assign the grid for smaller resolutions (s and down)
 */
export const SelectableTileGroup = ({
  title = '',
  itemColumnCount,
  itemList,
  propName,
  maxChoices,
  maxChoicesWarning,
  displayDescription = true,
  preserveValues = false,
  showErros = true
}: OwnProps): JSX.Element => {
  const newItemList = _.filter(itemList, 'availableIn');

  const { values, setFieldValue, touched, setFieldTouched, errors } =
    useFormikContext<MatchMakerAnswers>();

  useEffect(() => {
    // This is to remove the old values that do not exist in the new items
    // anymore to prevent having `dirty values` in database that results in
    // weird behavior in frontend
    const validValues = newItemList.map((item) => item.value);
    const currentValues = values[propName] || [];
    const validCurrentValues = currentValues.filter((v: unknown) => validValues.includes(v as string | number));

    if (preserveValues) return;

    setFieldValue(propName, validCurrentValues);
  }, []);

  const layoutItemClassName = itemColumnCount
    ? `${`u-${itemColumnCount[0]}/12`}${' '}
  ${`u-${itemColumnCount[1]}/12@s`}${' '}
  ${itemColumnCount.length > 2 ? `u-${itemColumnCount[2]}/12@xs` : ''}`
    : '';

  const handleClickItem = () => setFieldTouched(propName, true);

  const handleChangeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    let valueArray = values[propName] as (string | number | boolean)[];

    const copiedValueArray = [...valueArray];
    if (target.checked) {
      copiedValueArray.push(target.value);
    } else {
      copiedValueArray.splice(valueArray.indexOf(target.value), 1);
    }
    valueArray = copiedValueArray;

    setFieldValue(propName, valueArray);
  };

  const handleBlurItem = () => setFieldTouched(propName, true);

  return (
    <>
      <TileSelectGroup
        layoutItemClassName={layoutItemClassName}
        testId={`selectableTileGroup-${propName}`}
        data-testid={`selectableTileGroup-${propName}`}
        aria-describedby={`error-selectableTileGroup-${propName}`}
      >
        {newItemList.map((item) => {
          const disabledField =
            !!maxChoices &&
            (values[propName] as (string | number | boolean)[]).length >= maxChoices &&
            !(values[propName] as (string | number | boolean)[]).includes(item.value);

          return (
            item.availableIn && (
              <TrackableComponent
                key={`${propName}-${item.value}-wrapper`}
                trackingComponent="checkbox"
                trackingFunction={item.trackingName || ''}
              >
                {item.img ? (
                  <TileSelectWithImage
                    key={`${propName}-${item.value}`}
                    id={`${propName}-${String(item.value)}`}
                    name={`${propName}-${String(item.value)}`}
                    item={item}
                    title={<span className="u-font-size-fs0">{title || item.displayName}</span>}
                    checked={(values[propName] || []).includes(item.value as string)}
                    disabled={disabledField}
                    displayDescription={displayDescription}
                    onClick={handleClickItem}
                    onChange={handleChangeItem}
                    onBlur={handleBlurItem}
                  />
                ) : (
                  <TileSelect
                    className="u-font-size-fs0"
                    key={`${propName}-${item.value}`}
                    checkboxGroupName={`selectableTileGroup-${propName}`}
                    id={`${propName}-${String(item.value)}`}
                    title={<span className="u-font-size-fs0">{title || item.displayName}</span>}
                    value={item.value.toString()}
                    icon={item.icon || ''}
                    checked={(values[propName] || []).includes(item.value as string)}
                    disabled={disabledField}
                    aria-disabled={disabledField ? 'true' : 'false'}
                    onClick={handleClickItem}
                    onChange={handleChangeItem}
                    onBlur={handleBlurItem}
                  >
                    {displayDescription ? item.description : null}
                  </TileSelect>
                )}
              </TrackableComponent>
            )
          );
        })}
      </TileSelectGroup>

      <Layout>
        <Layout.Item>
          {showErros && touched[propName] && errors[propName] && (
            <FieldError
              id={`selectableTileGroup-${propName}`}
              errorMessage={(errors[propName] as string) || ''}
            />
          )}

          {maxChoices &&
            maxChoicesWarning &&
            (values[propName] as (string | number | boolean)[]).length >= maxChoices && (
              <p
                className={`u-mt-small u-mb-none ${bronsonIconSwitchEmpty(
                  'u-text-fs-1',
                  'u-text-fs-2',
                  'u-text-fs-2',
                  'u-text-fs-1',
                  'u-text-fs-1'
                )} `}
              >
                {maxChoicesWarning}
              </p>
            )}
        </Layout.Item>
      </Layout>
    </>
  );
};
