import { bronsonIconSwitchEmpty } from '../../lib/helpers/icon-helpers';
import { TrackableComponent } from '../../components/TrackableComponent/TrackableComponent';
import {
  Button,
  ButtonContainer,
  SectionHeading,
  ContentSection,
  PageWrap,
  Layout,
  RadioButtonGroup,
  RadioButton,
  FormField as BronsonFormField,
  FormHeading,
  ErrorMessage,
  Fieldset
} from '@vwfs-bronson/bronson-react';
import { Formik, FormikContextType } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { ContactOption, CustomerContactDetails, Dealer } from '../../../shared/types';
import { JOURNEY_STATE, useApplicationState } from '../../App.state';
import { Form, FormField, Row } from './components/FormComponents';
import { validationRules } from '../../../shared/validation-rules/contact-details-validator';
import { FormikErrorListener } from './FormikErrorListener';
import { isEmpty } from 'lodash';
import { RenderForBrands } from '../../lib/helpers/vehicle-helpers';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { MATCH_MAKER_FORM_ID } from '../../../shared/focusOnError';
import { FocusOnErrorComponent } from '../../../shared/FocusOnErrorComponent';

interface OwnProps {
  dealer: Dealer;
  onSubmit: (values: CustomerContactDetails) => void;
  initialValues: CustomerContactDetails;
  goBack: () => void;
}

export const CustomerInformationComponent: React.FC<OwnProps> = ({
  onSubmit,
  initialValues,
  goBack
}) => {
  const { t } = useTranslation('personal-information');
  const { dispatch } = useApplicationState();
  const validationSchema = Yup.object(validationRules(t));

  const updateAnalytics = (formikContext: FormikContextType<CustomerContactDetails>) => {
    if (formikContext.isSubmitting && !isEmpty(formikContext.errors)) {
      adobeDataLayer.formSubmitValidationErrorPersonalInformation(
        Object.keys(formikContext.errors)
      );
    }
  };

  const contactOptions = [
    { value: ContactOption.Phone, displayText: t('form.viaPhone') },
    { value: ContactOption.Email, displayText: t('form.viaEmail') }
  ];

  return (
    <ContentSection data-component-id="match-maker">
      <PageWrap>
        <ContentSection.ComponentWrapper>
          <SectionHeading level="2" className="u-mb-small">
            {t('page-title')}
          </SectionHeading>

          <ProgressBar
            value="100"
            currentStep="2"
            maxSteps="2"
            className={bronsonIconSwitchEmpty(
              'u-mt-small',
              'u-mt-xsmall',
              'u-mt-xsmall',
              'u-mt-xsmall',
              'u-mt-small'
            )}
          />
        </ContentSection.ComponentWrapper>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch({
              type: 'updateJourneyStatus',
              journeyStatus: JOURNEY_STATE.SUMMARY,
              isEditMode: false
            });
            onSubmit(values);
          }}
        >
          {({ handleSubmit, values, setFieldValue, errors, touched, isSubmitting }) => (
            <>
              {isSubmitting && <FocusOnErrorComponent validationResults={errors} />}
              <FormikErrorListener onError={updateAnalytics} />
              <Form
                id={MATCH_MAKER_FORM_ID}
                subtitle={t('form.subtitle')}
                onSubmit={handleSubmit}
                className="u-mt-large"
              >
                <Row>
                  <FormField
                    fieldName="firstName"
                    trackingComponent="inputbox"
                    trackingFunction="inputboxFirstName"
                  />
                  <FormField
                    fieldName="lastName"
                    trackingComponent="inputbox"
                    trackingFunction="inputboxLastName"
                  />
                </Row>

                <Row>
                  <FormField
                    fieldName="phoneNumber"
                    trackingComponent="inputbox"
                    trackingFunction="inputboxPhone"
                  />
                  <FormField
                    fieldName="email"
                    trackingComponent="inputbox"
                    trackingFunction="inputboxMailAddress"
                  />
                </Row>

                <RenderForBrands seat cupra>
                  <br />
                </RenderForBrands>
                <Row>
                  <RadioButtonGroup>
                    <Fieldset className="u-mb-small">
                      <FormHeading>{t('form.contactPreferenceQuestion')}</FormHeading>
                      <BronsonFormField
                        type="other"
                        testId="contactPreference"
                        errorMessage={
                          errors['contactPreference'] && touched['contactPreference'] ? (
                            <Layout.Item default="1/2">
                              <ErrorMessage>{errors['contactPreference']}</ErrorMessage>
                            </Layout.Item>
                          ) : null
                        }
                      >
                        {contactOptions.map((item) => (
                          <Layout.Item key={item.value} default="1/2">
                            <RadioButton
                              name="contactPreference"
                              value={item.value}
                              checked={values['contactPreference'] === item.value}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('contactPreference', e.target.value);
                              }}
                            >
                              {item.displayText}
                            </RadioButton>
                          </Layout.Item>
                        ))}
                      </BronsonFormField>
                    </Fieldset>
                  </RadioButtonGroup>
                </Row>
                <ButtonContainer nav>
                  <TrackableComponent
                    trackingComponent="button"
                    trackingFunction="buttonBackToDealerSearch"
                  >
                    <Button secondary={true} type="button" onClick={goBack}>
                      {t('shared:back-button')}
                    </Button>
                  </TrackableComponent>
                  <TrackableComponent
                    trackingComponent="button"
                    trackingFunction="buttonGoToSummary"
                  >
                    <Button type="submit">{t('form.wants-to-be-contacted')}</Button>
                  </TrackableComponent>
                </ButtonContainer>
              </Form>
            </>
          )}
        </Formik>
      </PageWrap>
    </ContentSection>
  );
};
