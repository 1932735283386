import { appConfig } from './../../config/index';
import axios from './axios';
import { SubmitAnswersRequestBody } from '../../shared/interface';

export const submitAnswers = async (requestBody: SubmitAnswersRequestBody) => {
  const { data } = await axios.post(
    `/api/${appConfig.brandName}/answers`,
    requestBody
  );

  return data;
};
