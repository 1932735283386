import { appConfig } from './../../config/index';
import { useTranslation } from 'react-i18next';

/**
 * - `useCustomTranslation` allows adding custom post-processing to the retrieved translation texts.
 * - Original purpose was to adjust pronouns accross all texts depending on the brand.
 * - Otherwise `useCustomTranslation` can be used interchangeably with `useTranslation`.
 */
export const useCustomTranslation = (topLevelKey: string) => {
  const { t } = useTranslation(topLevelKey);

  const _t = (translationKey: string, ...args: any[]) => t(translationKey, ...args);

  Object.defineProperty(_t, 'sieToDu', {
    value: (translationKey: string, ...args: any[]) => {
      const text = t(translationKey, ...args);
      switch (appConfig.brandName) {
        case 'cupra':
        case 'seat':
          return text
            .replace(/^Sie /g, 'Du ')
            .replace(/ Sie/g, ' du')
            .replace(/Sie /g, 'du ')
            .replace(/Sie./g, 'du.')
            .replace(/Sie,/g, 'du,');
        default:
          return t(translationKey, ...args);
      }
    }
  });

  return { t: _t } as {
    t: typeof t & {
      /**
       * Transform translation from using 'Sie' pronoun to 'du'.,
       */
      sieToDu: typeof t;
    };
  };
};
