import {
  ContentSection,
  PageWrap,
  SectionHeading,
  ItemTeaser,
  Layout
} from '@vwfs-bronson/bronson-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useApplicationState } from '../../App.state';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { CustomerHappiness } from '../../components/CustomerHappinessIndex';

export function Confirmation(): JSX.Element {
  const { t } = useTranslation('confirmation');
  const { state } = useApplicationState();

  useEffect(() => {
    adobeDataLayer.pageViewConfirmationPage();
  }, []);

  return (
    <>
      <ContentSection>
        <PageWrap>
          <div className="u-text-center">
            <i className="c-icon c-icon--xxlarge c-icon--[semantic-success] u-text-success" />
          </div>
        </PageWrap>
      </ContentSection>

      <ContentSection>
        <PageWrap size="small">
          <div className="u-text-center">
            <SectionHeading level="1">{t('title')}</SectionHeading>
            <div>{t('main-content')}</div>
          </div>
        </PageWrap>
      </ContentSection>

      <ContentSection>
        <PageWrap size="small">
          <Layout center>
            <Layout.Item default="1/2" xs="1/1">
              <ItemTeaser inline icon="semantic-dealer" title={t('contact-section.title')}>
                {state.dealer.name}
                <br />
                {state.dealer.phone}
              </ItemTeaser>
            </Layout.Item>
          </Layout>
        </PageWrap>
      </ContentSection>

      <CustomerHappiness />
    </>
  );
}
