import { CustomerContactDetails, Dealer, MatchMakerAnswers } from './types';

export enum Step {
    MM1 = 'MM1',
    MM2 = 'MM2',
    MM3 = 'MM3',
    MM4 = 'MM4',
    DEALER_SELECTION = 'DEALER_SELECTION',
    CONTACT_DETAILS = 'CONTACT_DETAILS',
    FINAL_CONFIRMATION = 'FINAL_CONFIRMATION'
}

export interface SubmitAnswersRequestBody {
    transactionId: string;
    matchMakerAnswers: MatchMakerAnswers;
    contactDetails?: CustomerContactDetails;
    selectedDealer?: string;
    currentStep: Step;
    isEditMode: boolean;
}