import { SelectableTileGroup } from '../../../components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { MatchMakerAnswers } from '../../../../shared/types';
import { transmissionList } from '../../helpers/vehicle-helpers';

export function TransmissionType(): JSX.Element {
  const { t } = useTranslation('match-maker');
  const { values } = useFormikContext<MatchMakerAnswers>();

  return (
    <SelectableTileGroup
      itemList={transmissionList(t)}
      itemColumnCount={[6, 6, 12]}
      propName="typeOfGears"
      propObject={values.typeOfGears ?? []}
      displayDescription={false}
    />
  );
}
