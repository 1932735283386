import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dealer } from '../../../shared/types';
import { useApplicationState } from '../../App.state';
import { loadingOrError } from '../../lib/ui/Loaders/GraphqlLoadingUtility';
import { RouterHelpers } from '../../router/router-helpers';

import { Step } from '../../../shared/interface';
import { getDealer, useGetDealer, useGetDealers } from '../../api/getDealers';
import { submitAnswers } from '../../api/submitAnswers';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { DealerSelectionComponent } from './DealerSelectionComponent';

const DEALER_COUNT = 4;

export function DealerSelection({ isEditMode = false }: { isEditMode?: boolean }): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useApplicationState();
  const { matchMakerAnswers, transactionId } = state;

  const [search, setSearch] = useState('');
  const [visibleCount, setVisibleCount] = useState(DEALER_COUNT);
  const [currentSearchWasSubmitted, setCurrentSearchWasSubmitted] = useState(false);

  const selectedDealerId = state.dealer.id || state.preSelectedDealerId;

  const {
    data: preSelectedDealer,
    error: preSelectedDealerError,
    isFetching: preSelectedDealerIsFetching
  } = useGetDealer(selectedDealerId, {
    enabled: Boolean(selectedDealerId)
  });

  const { data: dealers, error, isFetching } = useGetDealers(search, { enabled: search !== '' });

  useEffect(() => {
    adobeDataLayer.pageViewDealerSelectionInitial();
  }, []);

  const showMoreButtonVisible = dealers ? dealers.length > visibleCount : false;

  if (isFetching || error || preSelectedDealerIsFetching || preSelectedDealerError) {
    if (error || preSelectedDealerError) {
      adobeDataLayer.pageViewErrorPage({
        errorCode: '400',
        errorMessage: 'Technical error'
      });
    }

    return loadingOrError(
      isFetching || preSelectedDealerIsFetching,
      error || preSelectedDealerError ? new Error('Something went wrong') : undefined
    );
  }

  const onSearchDealer = async (input?: string) => {
    if (input) {
      setSearch(input);
      setVisibleCount(DEALER_COUNT);
      adobeDataLayer.pageViewDealerSelectionPostSearch();
    }
  };

  const loadMore = () => {
    if (dealers) {
      let newVisibleCount = visibleCount + DEALER_COUNT;

      if (newVisibleCount >= dealers.length) {
        newVisibleCount = dealers.length;
      }

      setVisibleCount(newVisibleCount);
    }
  };

  async function continueWithDealer(dealer: Dealer) {
    setIsLoading(true);

    // Error below indicates that v4 API from which we ultimately fetch dealer
    // data had no email which is necessary to for entire CJ flow to work out.
    try {
      await getDealer(dealer.id);
    } catch {
      console.error('Fetching dealer from v4 failed or contact data was misisng.');
      return;
    }

    submitAnswers({
      transactionId,
      matchMakerAnswers,
      selectedDealer: dealer.id,
      currentStep: Step.DEALER_SELECTION,
      isEditMode: false
    })
      .then((data) => {
        dispatch({ type: 'updateState', state: { ...state, transactionId: data, dealer } });

        if (state.preSelectedDealerId && dealer.id === state.preSelectedDealerId) {
          adobeDataLayer.selectDealer(dealer, true);
        } else {
          adobeDataLayer.selectDealer(dealer, false);
        }

        if (isEditMode) navigate(RouterHelpers.getSummaryPagePath());
        else navigate(RouterHelpers.getPersonalDetailsPath());
      })
      .catch((e) => {
        console.error('Error while submitting answers: ', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <DealerSelectionComponent
      prevCall={() => navigate(-1)}
      onFindDealer={onSearchDealer}
      isLoading={isLoading}
      continueWithDealer={continueWithDealer}
      loadMore={loadMore}
      dealers={dealers}
      preselectedDealer={preSelectedDealer}
      visibleCount={visibleCount}
      showMoreButtonVisible={showMoreButtonVisible}
      initialSearchTerm={search}
      currentSearchWasSubmitted={currentSearchWasSubmitted}
      setCurrentSearchWasSubmitted={setCurrentSearchWasSubmitted}
    />
  );
}
