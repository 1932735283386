import { appConfig } from './../../../config/index';
export const ERROR_NO_DATA = new Error(
  'No data available after loading is done'
);

export const CURRENCY_SYMBOL = '€';

export const MIN_ENGINE_POWER = (() => {
  switch (appConfig.brandName) {
    case 'audi':
      return 60;
    case 'vwn':
      return 75;
    default:
      return 50;
  }
})();

export const MAX_ENGINE_POWER = (() => {
  switch (appConfig.brandName) {
    case 'audi':
      return 460;
    case 'vwn':
      return 177;
    default:
      return 340;
  }
})();

export const MIN_MONTHLY_BUDGET = (() => {
  switch (appConfig.brandName) {
    default:
      return 100;
  }
})();

export const MAX_MONTHLY_BUDGET = (() => {
  switch (appConfig.brandName) {
    default:
      return 1500;
  }
})();
