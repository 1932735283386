import { Input } from '@vwfs-bronson/bronson-react';
import { useFormikContext } from 'formik';

type OwnProps = {
  fieldName: string;
  placeholder: string;
  addonText?: string;
};

export const NumericInput: React.FunctionComponent<OwnProps> = ({
  fieldName,
  placeholder,
  addonText
}: OwnProps): JSX.Element => {
  const formikProps = useFormikContext<{ [index: string]: number }>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      formikProps.setFieldValue(fieldName, 0, true);
    } else if (isNaN(Number(event.target.value))) {
      return;
    } else {
      formikProps.setFieldValue(fieldName, parseFloat(event.target.value), true);
    }
  };

  return (
    <Input
      name={fieldName}
      id={`${fieldName}-id`}
      inputMode="numeric"
      type="text"
      pattern="[0-9 ,.]*"
      value={formikProps.values[fieldName] || 0}
      min={0}
      onBlur={formikProps.handleBlur}
      onChange={handleChange}
      onFocus={(event: React.ChangeEvent<HTMLInputElement>) => event.target.select()}
      placeholder={placeholder}
      addonText={addonText}
    />
  );
};
