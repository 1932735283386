import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ContactOption } from '../types';

const regExFirstName = new RegExp(
  "^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšßẞ][a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-']*$"
);
const regExLastName = new RegExp(
  "^[a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠšßẞ][a-zA-ZÄÅáâäàãåÉéêëèíîïìÖóôöòõÜúüûùÇçÑñŠš ß\\-']*$"
);

export const validationRules = (t: TFunction) => {
  return {
    firstName: Yup.string()
      .min(2, t('form.firstName-minChars'))
      .max(35, t('form.firstName-maxChars'))
      .matches(regExFirstName, t('form.firstName-invalid'))
      .required(t('form.firstName-required'))
      .label(t('form.firstName')),
    lastName: Yup.string()
      .min(2, t('form.lastName-minChars'))
      .max(35, t('form.lastName-maxChars'))
      .matches(regExLastName, t('form.lastName-invalid'))
      .required(t('form.lastName-required'))
      .label(t('form.lastName')),
    phoneNumber: Yup.string()
      .required(t('form.phoneNumber-invalid'))
      .min(3, t('form.phoneNumber-invalid'))
      .max(15, t('form.phoneNumber-invalid'))
      .matches(/^[+0-9]+$/, t('form.phoneNumber-invalid'))
      .label(t('form.phoneNumber')),
    email: Yup.string()
      .required(t('form.email-invalid'))
      .email(t('form.email-invalid'))
      .label(t('form.email')),
    contactPreference: Yup.string()
      .required(t('form.contactPreference-invalid'))
      .oneOf(Object.keys(ContactOption))
  };
};
