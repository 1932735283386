import { DefaultError } from './DefaultError';
import { DefaultLoading } from './DefaultLoading';

function loadingOrError(loading: boolean, error?: Error): JSX.Element {
  if (loading) return <DefaultLoading />;

  if (error) return <DefaultError />;

  throw new Error('wrong usage');
}

export { loadingOrError };
