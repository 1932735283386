import { appConfig } from './config/index';

interface PageInfo {
  pageName?: string;
  intendedCustomerDeviceType: 'all';
  version: string;
  language: 'de';
  market: 'DE';
  publisher: 'DU';
  releaseDate?: string;
}

interface Category {
  primaryCategory: 'Digital renewal' | 'digital-renewal';
  siteType: 'standalone';
  maturityLevel?: string;
  contractType: 'Renewal';
}

interface Attributes {
  journeyType: 'customer-facing-product-journey';
  brand: string;
  viewChange?: string;
  transactionID: string;
  context: string;
}

interface Core {
  stagingEnvironment?: 'DEV' | 'INT' | 'CONS' | 'PROD';
  dataLayerVersion: '2.8';
  pageInfo: PageInfo;
  category: Category;
  attributes: Attributes;
}

interface AdobeDataLayerError {
  errorCode?: string;
  errorMessage?: string;
  errorCausingURL?: string;
}

interface Engine {
  fuelType?: string;
  emission?: number;
  transmission?: string;
}

interface DealerData {
  companyId?: string;
  companyName?: string;
  address: Address;
  regionId: string;
}

interface Address {
  street?: string;
  zipCode?: string;
  city?: string;
}

interface Design {
  browserResolutionBreakpoint?: string;
}

interface CustomerData {
  loginStatus?: boolean;
  loggedInUserGroup: 'private' | 'business (small)' | 'business (corporate)' | 'business';
  yearOfBirth?: string;
  gender?: string;
  address: {
    zipCode?: string;
  };
}

interface EventInfo {
  eventType:
    | 'Access'
    | 'Product journey'
    | 'pageView'
    | 'interAction'
    | 'SummaryPage'
    | 'Send lead to Dealer'
    | 'Error'
    | 'Form';
  eventAction: 'Success' | 'Failed' | 'Technical error' | 'Page not found' | 'Field error' | 'End';
  eventTargetURL?: string;
  linkInformation?: string;
}

interface DataPrivacyStatement {
  allowPostalAds?: boolean;
  allowPhoneAds?: boolean;
  allowElectronicAds?: boolean;
  allowMailAds?: boolean;
  allowElectronicInvoices?: boolean;
}

interface AdobeDataLayerEvent {
  eventInfo: EventInfo;
}

interface Form {
  type?: 'Geographic data' | 'Dealer selection' | 'Personal data' | '';
  name?: 'Personal location' | 'Dealer selection' | 'Personal data' | '';
  fieldValues?: {};
  lastTouchedField?: string;
  errorFields?: string[];
}

interface Filter {
  filterName: string;
  filterValue: (string | null)[];
}

interface Video {
  videoID?: string;
  videoName?: string;
  videoLength?: string;
}

interface AdobeDataLayerObject {
  core: Core;
  error: AdobeDataLayerError;
  form: Form;
  dealerData: DealerData;
  design: Design;
  customerData: CustomerData;
  filter: Filter[];
  dataPrivacyStatement: DataPrivacyStatement;
  event: AdobeDataLayerEvent[];
  video: Video;
}

interface Window {
  du_digitalData: AdobeDataLayerObject;
}

export enum FinancePlan {
  Leasing = 'Leasing',
  Credit = ['seat', 'cupra', 'vw', 'vwn'].indexOf(appConfig.brandName) >= 0
    ? 'AutoCredit'
    : 'VarioCredit',
  Unknown = '_Unknown'
}
