import React from 'react';
import {
  ContactOption,
  CustomerContactDetails,
  Dealer,
  ICarSearchType,
  MatchMakerAnswers
} from '../shared/types';
import { MAX_ENGINE_POWER, MIN_ENGINE_POWER, MIN_MONTHLY_BUDGET } from './lib/helpers/constants';

export enum JOURNEY_STATE {
  STARTED,
  MM_FINISHED,
  FINANCING,
  DEALER_SELECTION,
  SUMMARY,
  CONFIRMATION
}

export enum FlowType {
  SHORT = 'short',
  LONG = 'long',
  HYBRID = 'hybrid', // User previously selected long journey, but changed mind and selected short - it affects field visibility on summary,
  UNSELECTED = 'unselected'
}

export type EditableSummarySection =
  | 'characteristics'
  | 'finance'
  | 'features'
  | 'additional-info'
  | 'dealer'
  | 'personal-details';

interface RenewalApplicationState {
  matchMakerAnswers: MatchMakerAnswers;
  customerInformation: CustomerContactDetails;
  dealer: Dealer;
  transactionId: string;
  preSelectedDealerId: string;
  currentJourneyStatus: JOURNEY_STATE;
  isEditMode: boolean;
  legalEntity: string;
  flowType: FlowType;
  editedSections: Array<EditableSummarySection>;
}

const TRANSACTION_ID_PARAM_NAME = 'transactionId';
const DEALER_ID_PARAM_NAME = 'DealerCompanyNumber';
const LEGAL_ENTITY_PARAM_NAME = 'LegalEntity';
const searchParams = new URLSearchParams(window.location.search);

let transactionIdParameter = '';
if (searchParams.has(TRANSACTION_ID_PARAM_NAME)) {
  transactionIdParameter = searchParams.get(TRANSACTION_ID_PARAM_NAME) ?? '';
}

let preSelectedDealerIdParameter = '';
if (searchParams.has(DEALER_ID_PARAM_NAME)) {
  preSelectedDealerIdParameter = searchParams.get(DEALER_ID_PARAM_NAME) ?? '';
}

let legalEntityParameter = '';
if (searchParams.has(LEGAL_ENTITY_PARAM_NAME)) {
  legalEntityParameter = searchParams.get(LEGAL_ENTITY_PARAM_NAME) ?? '';
}

export const initialState: RenewalApplicationState = {
  matchMakerAnswers: {
    condition: [],
    typeOfCar: [],
    typeOfGears: [],
    fuelTypes: [],
    expectedDuration: 36,
    expectedMonthlyPayment: MIN_MONTHLY_BUDGET,
    milesPerYear: 10000,
    isCompleted: false,
    minPower: MIN_ENGINE_POWER,
    maxPower: MAX_ENGINE_POWER,
    carSearchType: ICarSearchType.BYTYPE,
    modelOfCar: [],
    specialEquipments: [],
    additionalServices: [],
    financePlan: [],
    additionalDepositRadio: false,
    additionalDeposit: 0
  },
  customerInformation: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    contactPreference: ContactOption.Email
  },
  dealer: {
    name: '',
    street: '',
    zip: '',
    city: '',
    houseNumber: '',
    phone: '',
    id: '',
    email: '',
    regionId: ''
  },

  currentJourneyStatus: JOURNEY_STATE.STARTED,
  transactionId: transactionIdParameter,
  preSelectedDealerId: preSelectedDealerIdParameter,
  isEditMode: false,
  legalEntity: legalEntityParameter,
  flowType: FlowType.UNSELECTED,
  editedSections: []
};

const AppStateContext = React.createContext<{
  state: RenewalApplicationState;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => {}
});

type Action =
  | { type: 'updateState'; state: RenewalApplicationState }
  | { type: 'updateMatchMakerAnswers'; matchMakerAnswers: MatchMakerAnswers }
  | { type: 'updateCustomerInformation'; customer: CustomerContactDetails }
  | { type: 'updateDealer'; dealer: Dealer }
  | { type: 'updateTransactionId'; transactionId: string }
  | { type: 'updateJourneyStatus'; journeyStatus: JOURNEY_STATE; isEditMode?: boolean }
  | { type: 'markEditedSection'; sectionName: EditableSummarySection }
  | { type: 'updateEditStatus'; isEditMode: boolean };

function reducer(state: RenewalApplicationState, action: Action): RenewalApplicationState {
  switch (action.type) {
    case 'updateState':
      return action.state;

    case 'updateMatchMakerAnswers':
      return {
        ...state,
        matchMakerAnswers: { ...action.matchMakerAnswers }
      };

    case 'updateCustomerInformation':
      return {
        ...state,
        customerInformation: { ...action.customer }
      };

    case 'updateDealer':
      return {
        ...state,
        dealer: { ...action.dealer }
      };

    case 'updateTransactionId':
      return {
        ...state,
        transactionId: action.transactionId
      };

    case 'updateJourneyStatus':
      if (state.isEditMode) {
        return state;
      }
      return {
        ...state,
        currentJourneyStatus: action.journeyStatus,
        ...(action.isEditMode ? { isEditMode: action.isEditMode } : {})
      };

    case 'updateEditStatus':
      return {
        ...state,
        isEditMode: action.isEditMode
      };

    case 'markEditedSection': {
      const editedSections = state.editedSections;
      editedSections.push(action.sectionName);

      return {
        ...state,
        editedSections: editedSections
      };
    }
  }
}

export const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};

export const useApplicationState = (): {
  state: RenewalApplicationState;
  dispatch: React.Dispatch<Action>;
} => {
  return React.useContext(AppStateContext);
};
