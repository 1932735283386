import {
  FieldValidators,
  validateChoiceCountUpTo,
  validateNumberRange,
  validateNumberRangeMax,
  makeOptional,
  makeOptionalIf
} from './validation-utility';
import { TFunction } from 'i18next';

import { ICarSearchType, MatchMakerAnswers } from '../types';

interface AdditionalDeposit {
  [brand: string]: number;
}

const MAX_ADDITIONAL_DEPOSIT: AdditionalDeposit = {
  vw: 50000,
  volkswagen: 50000,
  audi: 50000,
  seat: 50000,
  cupra: 50000,
  skoda: 50000,
  vwn: 50000
};

const brandMaxAdditionalDeposit = ((): number => {
  return MAX_ADDITIONAL_DEPOSIT[process.env.REACT_APP_BRAND || 'audi'];
})();

function getAllFieldValidators(t: TFunction, answers: MatchMakerAnswers): FieldValidators {
  const minMonthlyPayment = 100;
  const maxMonthlyPayment = 1500;

  const minAdditionalDeposit = 1;
  const maxAdditionalDeposit = brandMaxAdditionalDeposit;

  const isCarTypeSubmitted = !!answers.typeOfCar?.length;
  const isCarModelSubmitted = !!answers.modelOfCar?.length;

  return {
    condition: [
      validateChoiceCountUpTo(
        t('match-maker:question-pages.items.condition.validation.required'),
        '',
        999
      )
    ],
    typeOfCar: [
      makeOptionalIf(
        !isCarTypeSubmitted && isCarModelSubmitted,
        validateChoiceCountUpTo(
          t('match-maker:question-pages.items.bodystyle.validation.required'),
          t('match-maker:question-pages.items.bodystyle.validation.max'),
          3
        ),
        (v) => Array.isArray(v) && v.length === 0
      )
    ],
    modelOfCar: [
      makeOptionalIf(
        isCarTypeSubmitted && !isCarModelSubmitted,
        validateChoiceCountUpTo(
          t('match-maker:question-pages.items.model.validation.required'),
          '',
          999
        ),
        (v) => Array.isArray(v) && v.length === 0
      )
    ],
    typeOfGears: [
      makeOptional(
        validateChoiceCountUpTo(
          t('match-maker:question-pages.items.gears.validation.required'),
          '',
          999
        ),
        (v) => Array.isArray(v) && v.length === 0
      )
    ],
    fuelTypes: [
      validateChoiceCountUpTo(
        t('match-maker:question-pages.items.fuel.validation.required'),
        '',
        999
      )
    ],
    expectedMonthlyPayment: [
      validateNumberRange(
        t('match-maker:question-pages.items.installments.validation.required'),
        t('match-maker:question-pages.items.installments.validation.min'),
        t('match-maker:question-pages.items.installments.validation.max'),
        minMonthlyPayment,
        maxMonthlyPayment
      )
    ],
    additionalDeposit: [
      validateNumberRangeMax(
        answers.additionalDepositRadio
          ? t('match-maker:question-pages.items.additional-deposit.validation.required')
          : '', // empty for optional fields
        t('match-maker:question-pages.items.additional-deposit.validation.required', {
          value: minAdditionalDeposit.toLocaleString('de-DE'),
          currency: '€'
        }),
        t('match-maker:question-pages.items.additional-deposit.validation.max', {
          value: maxAdditionalDeposit.toLocaleString('de-DE'),
          currency: '€'
        }),
        minAdditionalDeposit,
        maxAdditionalDeposit
      )
    ],
    specialEquipments: [],
    additionalServices: [],
    financePlan: [
      makeOptional(
        validateChoiceCountUpTo(
          t('match-maker:question-pages.items.finance-plan.validation.required'),
          '',
          999
        ),
        (v) => Array.isArray(v) && v.length === 0
      )
    ]
  };
}

function getFieldValidators(
  t: TFunction,
  fields: (keyof Omit<MatchMakerAnswers, 'isCompleted'>)[],
  answers: MatchMakerAnswers
  // eslint-disable-next-line @typescript-eslint/ban-types
) {
  const allFieldsValidator = getAllFieldValidators(t, answers);

  const result = {};
  fields.forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (fieldName) => (result[fieldName] = allFieldsValidator[fieldName])
  );

  return result;
}

function getMM1Fields(
  carSearchType?: ICarSearchType
): (keyof Omit<MatchMakerAnswers, 'isCompleted'>)[] {
  return ['condition', 'typeOfCar', 'modelOfCar','fuelTypes'];
}

const MM2Fields: (keyof Omit<MatchMakerAnswers, 'isCompleted'>)[] = [
  'expectedMonthlyPayment','additionalDeposit','expectedDuration'
];
const MM3Fields: (keyof Omit<MatchMakerAnswers, 'isCompleted'>)[] = ['typeOfGears','minPower', 'maxPower', 'specialEquipments'];
const MM4Fields: (keyof Omit<MatchMakerAnswers, 'isCompleted'>)[] = ['financePlan', 'milesPerYear','additionalServices'];

export {
  getFieldValidators,
  getAllFieldValidators,
  brandMaxAdditionalDeposit,
  getMM1Fields,
  MM2Fields,
  MM3Fields,
  MM4Fields
};
