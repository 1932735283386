import React from 'react';
import { Main, ContentSection } from '@vwfs-bronson/bronson-react';
import { ToastContainer } from 'react-toastify';

import { ScrollToTopOnNavigation } from '../router/ScrollToTopOnNavigation';
import Footer from './components/Footer/Footer';
import HeaderPrivate from './components/Header/HeaderContainerPrivate';

const MasterPage: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <ToastContainer
        position={'top-right'}
        pauseOnHover={true}
        hideProgressBar={true}
        autoClose={6000}
      />
      <ScrollToTopOnNavigation>
        <div className="o-root">
          <HeaderPrivate />
          <Main>
            <ContentSection pageWrap>{children}</ContentSection>
          </Main>
          <Footer />
        </div>
      </ScrollToTopOnNavigation>
    </>
  );
};

export default MasterPage;
