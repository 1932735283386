import React from 'react';
// import { Layout, Button } from '@vwfs-bronson/bronson-react';
import { SelectableTileGroup } from '../../../components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
// import { FinancePlan as FinancePlanType } from '../../../../custom-types.d';
import { MatchMakerAnswers } from '../../../../shared/types';
import { financePlanList } from '../../helpers/vehicle-helpers';
// import {
//   LearnMoreAboutFinanceModalManual,
//   VIDEO_ID_CREDIT,
//   VIDEO_ID_LEASING
// } from './components/FinanceModals';
// import { adobeDataLayer } from '../../adobe-analytics-data-layer/AdobeDataLayer';

export function FinancePlan(): JSX.Element {
  const { t } = useTranslation('match-maker');
  const { values } = useFormikContext<MatchMakerAnswers>();
  const itemList = financePlanList(t);

  // const [showLeasingVideo, setShowLeasingVideo] = React.useState(false);
  // const [showCreditVideo, setShowCreditVideo] = React.useState(false);

  return (
    <>
      {/* <LearnMoreAboutFinanceModalManual
        shown={showCreditVideo}
        onClose={() => setShowCreditVideo(false)}
        financePlan={FinancePlanType.Credit}
      />
      <LearnMoreAboutFinanceModalManual
        shown={showLeasingVideo}
        onClose={() => setShowLeasingVideo(false)}
        financePlan={FinancePlanType.Leasing}
      /> */}
      <SelectableTileGroup
        itemList={itemList as any}
        itemColumnCount={[6, 6, 12]}
        propName="financePlan"
        propObject={values.financePlan || {}}
      />
      {/* <Layout equalHeight className="u-pt-small">
        {itemList.map(
          (item, index) =>
            item.availableIn &&
            item.hasVideo && (
              <Layout.Item key={`${index}-${item.value}-video`} default="1/2">
                <Button
                  link
                  icon="semantic-play"
                  iconReversed
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();

                    const financePlan = item.value as FinancePlanType;
                    if (financePlan === FinancePlanType.Leasing) {
                      adobeDataLayer.interactionVideoLinkClicked(VIDEO_ID_LEASING, financePlan);
                      setShowLeasingVideo(true);
                    } else {
                      adobeDataLayer.interactionVideoLinkClicked(VIDEO_ID_CREDIT, financePlan);
                      setShowCreditVideo(true);
                    }
                  }}
                >
                  {t('match-maker:question-pages.items.finance-plan.video-trigger')}
                </Button>
              </Layout.Item>
            )
        )}
      </Layout> */}
    </>
  );
}
