import { useLocation } from 'react-router-dom';

export const ScrollToTopOnNavigation = ({ children }: any) => {
  const location = useLocation();
  if (location.hash && location.hash !== '') {
    setTimeout(() => {
      const id = location.hash.replace('#', '');
      let element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        setTimeout(() => {
          element = document.getElementById(id);
          element?.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
      }
    }, 50);
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return children;
};
