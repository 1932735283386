
import { TFunction } from 'i18next';

export const mapOptionsToItems = (_t: TFunction, translationKey: string) => {
  const options = _t(translationKey, { returnObjects: true }) as Array<Record<string, string>>;

  return options.map((s) => {
    return {
      displayText: s.name,
      value: s.name,
      tooltip: s.tooltip
    };
  });
};