import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Main,
  ContentSection,
  SectionHeading,
  Button,
  Heading,
  Layout
} from '@vwfs-bronson/bronson-react';

import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { RouterHelpers } from '../../router/router-helpers';

const ErrorPage404: React.FunctionComponent<{
  location?: string;
  noId?: boolean;
  noLegalEntity?: boolean;
}> = ({ location, noId, noLegalEntity }) => {
  const { t } = useTranslation('error-pages');

  useEffect(() => {
    if (t) {
      adobeDataLayer.pageViewErrorPage({
        errorCode: '404',
        errorMessage: t('404-description'),
        errorCausingURL: location
      });
    }
  }, [location, t]);

  if (noId) {
    console.error('Required \'transactionId\' parameter is missing in the URL or has no value.');
  }
  if (noLegalEntity) {
    console.error('Required \'LegalEntity\' parameter is missing in the URL or has no value.');
  }

  const errorTextKey = (noId || noLegalEntity)
    ? '404-description-no-legal-entity'
    : '404-description';

  return (
    <Main>
      <ContentSection pageWrap>
        <Heading level="3">404</Heading>
        <SectionHeading level="1" subtitle={t(errorTextKey)}>
          {t('404-title')}
        </SectionHeading>
        <Layout className="u-mt-large">
          <Layout.Item default="3/12" m="1/3" s="1/1">
            <Link to={RouterHelpers.getEntryPointPath()}>
              <Button full>{t('shared:back-button')}</Button>
            </Link>
          </Layout.Item>
        </Layout>
      </ContentSection>
    </Main>
  );
};

export default ErrorPage404;
