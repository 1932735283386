export class RouterHelpers {
  static prefix = '';

  static withPrefix(path: string): string {
    return `${this.prefix}${path}`;
  }

  static getEntryPointPath(): string {
    return this.withPrefix('/');
  }

  static getLandingPageUrl(legalEntity: 'leasing' | 'financing'): string {
    const landingPageUrlMapping = {
      audi: {
        leasing: 'https://kons.www.vwfs.de/meinneueraudi/audi-dr1',
        financing: 'https://kons.www.vwfs.de/meinneueraudi/audi-dr1'
      },
      cupra: {
        leasing: 'https://www.vwfs.de/meinneuercupra/cupra-leasing-dr1',
        financing: 'https://www.vwfs.de/meinneuercupra/cupra-finanzierung-dr1'
      },
      seat: {
        leasing: 'https://www.vwfs.de/meinneuerseat/seat-leasing-dr1',
        financing: 'https://www.vwfs.de/meinneuerseat/seat-finanzierung-dr1'
      },
      vw: {
        leasing: 'https://www.vwfs.de/meinneuervw/vw-leasing-dr1',
        financing: 'https://www.vwfs.de/meinneuervw/vw-finanzierung-dr1'
      },
      vwn: {
        leasing: 'https://google.com',
        financing: 'https://google.com'
      }
    };

    const mappedUrl =
      landingPageUrlMapping[process.env.REACT_APP_BRAND as keyof typeof landingPageUrlMapping][
        legalEntity
      ];

    return `${mappedUrl}${window.location.search}`;
  }

  static getMatchMakerPath(): string {
    return this.withPrefix('/match-maker');
  }
  static getMatchMakerQuestionPage1Path(): string {
    return this.withPrefix('/match-maker/question-page1');
  }
  static getMatchMakerQuestionPage2Path(): string {
    return this.withPrefix('/match-maker/question-page2');
  }
  static getMatchMakerQuestionPage3Path(): string {
    return this.withPrefix('/match-maker/question-page3');
  }
  static getMatchMakerQuestionPage4Path(): string {
    return this.withPrefix('/match-maker/question-page4');
  }

  static getMatchMakerQuestionPage1EditPath(): string {
    return this.withPrefix('/match-maker/edit/question-page1');
  }

  static getMatchMakerQuestionPage2EditPath(): string {
    return this.withPrefix('/match-maker/edit/question-page2');
  }

  static getMatchMakerQuestionPage3EditPath(): string {
    return this.withPrefix('/match-maker/edit/question-page3');
  }
  static getMatchMakerQuestionPage4EditPath(): string {
    return this.withPrefix('/match-maker/edit/question-page4');
  }

  static getDealerSelectionPagePath(): string {
    return this.withPrefix('/dealer-selection');
  }
  static getDealerSelectionEditPagePath(): string {
    return this.withPrefix('/dealer-selection/edit');
  }

  static getPersonalDetailsPath(): string {
    return this.withPrefix('/dealer-selection/personal-information');
  }

  static getConfirmationPagePath(): string {
    return this.withPrefix('/confirmation');
  }

  static getSummaryPagePath(): string {
    return this.withPrefix('/summary');
  }

  static get499ErrorPagePath(): string {
    return this.withPrefix('/error-499');
  }
}
