import {
  Button,
  ButtonContainer,
  Card,
  ContentSection,
  Layout,
  PageWrap,
  SectionHeading,
  Spinner
} from '@vwfs-bronson/bronson-react';
import { FieldError } from '../../components/FieldError/FieldError';
import { TrackableComponent } from '../../components/TrackableComponent/TrackableComponent';
import { bronsonIconSwitchEmpty } from '../../lib/helpers/icon-helpers';

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dealer } from '../../../shared/types';

import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import { DefaultLoading } from '../../lib/ui/Loaders/DefaultLoading';
import { LoadMoreButton } from './components/LoadMoreButton';

interface OwnProps {
  prevCall: () => void;
  continueWithDealer: (dealer: Dealer) => void;
  onFindDealer: (search: string) => void;
  dealers: Dealer[] | undefined;
  preselectedDealer: Dealer | undefined;
  isLoading?: boolean;
  loadMore: () => void;
  visibleCount: number;
  showMoreButtonVisible: boolean;
  initialSearchTerm: string;
  currentSearchWasSubmitted: boolean;
  setCurrentSearchWasSubmitted: (value: boolean) => void;
}

export const DealerSelectionComponent: React.FC<OwnProps> = ({
  prevCall,
  continueWithDealer,
  onFindDealer,
  dealers,
  preselectedDealer,
  isLoading = false,
  loadMore,
  visibleCount,
  showMoreButtonVisible,
  initialSearchTerm,
  currentSearchWasSubmitted,
  setCurrentSearchWasSubmitted
}) => {
  const { t } = useTranslation('dealer-search');

  const showNoResultsText = currentSearchWasSubmitted && !dealers?.length;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setCurrentSearchWasSubmitted(true);
    event.preventDefault();
    // @ts-ignore - I was not able to correctly type the FormEvent. The searchTerm
    // corresponds to the name of the input field.
    const searchTerm = event.target.searchTerm.value;
    if (searchTerm !== '') {
      onFindDealer(searchTerm);
    }
  };

  if (isLoading) return <DefaultLoading />;

  return (
    <ContentSection data-component-id="dealer-search">
      <PageWrap>
        <ContentSection.ComponentWrapper>
          <SectionHeading level="2" className="u-mb-small">
            {t('page-title')}
          </SectionHeading>

          <ProgressBar
            value={'50'}
            currentStep={'1'}
            maxSteps={'2'}
            className={bronsonIconSwitchEmpty(
              'u-mt-small',
              'u-mt-xsmall',
              'u-mt-xsmall',
              'u-mt-xsmall',
              'u-mt-small'
            )}
          />
        </ContentSection.ComponentWrapper>

        <ContentSection.ComponentWrapper>
          {preselectedDealer ? (
            <SectionHeading className="u-mb-xlarge" level={2}>
              {t('pre-selected-dealer-continue')}
            </SectionHeading>
          ) : null}
          <div
            className={classNames('o-layout', {
              'u-text-center': !preselectedDealer
            })}
          >
            {preselectedDealer ? (
              <>
                <div className="o-layout__item u-1/4 u-1/1@s u-1/1@m" style={{ maxWidth: '300px' }}>
                  <h5>{t('pre-selected-dealer-title')}</h5>
                  <Card
                    className="u-text-wrap"
                    element="div"
                    title={preselectedDealer.name}
                    footer
                    buttons={[
                      <TrackableComponent
                        trackingComponent="button"
                        trackingFunction="continueWithDealerButton"
                        key={preselectedDealer.id}
                      >
                        <Button onClick={() => continueWithDealer(preselectedDealer)} full>
                          {t('results.continue-button')}
                        </Button>
                      </TrackableComponent>
                    ]}
                  >
                    <p>
                      {preselectedDealer.street} {preselectedDealer.houseNumber}
                      <br></br>
                      {`${preselectedDealer.zip} ${preselectedDealer.city}`}
                    </p>
                  </Card>
                </div>
              </>
            ) : null}
            <Layout.Item
              className={classNames('o-layout__item', {
                'u-3/4 u-1/1@s u-1/1@m': preselectedDealer
              })}
            >
              <PageWrap size="medium">
                <ContentSection.ComponentWrapper>
                  <SectionHeading level="3" subtitle={t('subtitle')}>
                    {t('title')}
                  </SectionHeading>
                </ContentSection.ComponentWrapper>
                <ContentSection.ComponentWrapper>
                  <form onSubmit={handleSubmit}>
                    <div className={`c-input ${showNoResultsText ? 'u-mb-xsmall' : 'u-mb-xlarge'}`}>
                      <TrackableComponent
                        trackingComponent="inputbox"
                        trackingFunction="dealerSearchInput"
                      >
                        <input
                          id="searchTerm"
                          type="text"
                          defaultValue={initialSearchTerm}
                          name="searchTerm"
                          className="c-input__input"
                          placeholder={t('input-placeholder')}
                          onChange={() => {
                            setCurrentSearchWasSubmitted(false);
                          }}
                        />
                      </TrackableComponent>
                      <button className="c-input__addon">
                        <i
                          className="c-icon c-icon--[semantic-search]"
                          aria-hidden="true"
                          role="img"
                        ></i>
                      </button>
                    </div>

                    {showNoResultsText ? (
                      <Layout>
                        <Layout.Item>
                          <FieldError
                            id="noDealerResultWarning"
                            errorMessage={t('dealer-search-404')}
                          />
                        </Layout.Item>
                      </Layout>
                    ) : null}

                    <ButtonContainer nav className="u-mt-xxsmall">
                      <TrackableComponent
                        trackingComponent="button"
                        trackingFunction="buttonBackToDealerAppointmentPage"
                      >
                        <Button
                          secondary
                          inContainer
                          onClick={prevCall}
                          className="u-1/6 u-1/3@m u-1/1@xs"
                        >
                          {t('shared:back-button')}
                        </Button>
                      </TrackableComponent>
                      <TrackableComponent
                        trackingComponent="button"
                        trackingFunction="buttonSearchDealer"
                      >
                        <Button inContainer type="submit" className="u-1/6 u-1/3@m u-1/1@xs">
                          {t('search-button')}
                        </Button>
                      </TrackableComponent>
                    </ButtonContainer>
                  </form>
                </ContentSection.ComponentWrapper>
              </PageWrap>
            </Layout.Item>
          </div>
        </ContentSection.ComponentWrapper>

        {isLoading ? (
          <Spinner center />
        ) : (
          <>
            {dealers && dealers.length > 0 && (
              <ContentSection.ComponentWrapper>
                <h5 className="u-mt">{t('results.header')}</h5>
                <Layout equalHeight>
                  {dealers.slice(0, visibleCount).map((dealer) => (
                    <Layout.Item default="1/4" xs="1/1" s="1/2" m="1/3" key={dealer.id}>
                      <Card
                        className="u-text-wrap"
                        element="div"
                        title={dealer.name}
                        footer
                        buttons={[
                          <TrackableComponent
                            trackingComponent="button"
                            trackingFunction="continueWithDealerButton"
                            key={dealer.id}
                          >
                            <Button onClick={() => continueWithDealer(dealer)} full>
                              {t('results.continue-button')}
                            </Button>
                          </TrackableComponent>
                        ]}
                      >
                        <p>
                          {dealer.street} {dealer.houseNumber}
                          <br></br>
                          {`${dealer.zip} ${dealer.city}`}
                        </p>
                      </Card>
                    </Layout.Item>
                  ))}
                </Layout>

                <LoadMoreButton
                  t={t}
                  showMoreButtonVisible={showMoreButtonVisible}
                  loadMore={loadMore}
                />
              </ContentSection.ComponentWrapper>
            )}
          </>
        )}
      </PageWrap>
    </ContentSection>
  );
};
