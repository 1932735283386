import React from 'react';
import { Fieldset, FormHeading } from '@vwfs-bronson/bronson-react';

interface Props {
  heading: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  infoIcon?: any;
}

export const Question: React.FC<Props> = (props) => {
  const { heading, infoIcon, children } = props;

  return (
    <Fieldset className='u-mv-large'>
      <FormHeading className='u-mb-large u-h2'>
        {heading} {infoIcon ? infoIcon : null}
      </FormHeading>
      {children}
    </Fieldset>
  );
};
