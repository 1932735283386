import { Progress, Layout } from '@vwfs-bronson/bronson-react';

type OwnProps = {
  value: string;
  currentStep: string | number;
  maxSteps: string | number;
  className?: string;
};

export const ProgressBar: React.FC<OwnProps> = ({ value, currentStep, maxSteps, className }) => {
  return (
    <Layout middle className="u-mb-xlarge">
      <Layout.Item default="11/12" m="10/12" xs="9/12">
        <Progress value={value} className={className ?? ''} />
      </Layout.Item>
      <Layout.Item default="1/12" m="2/12" xs="3/12">{`${currentStep} / ${maxSteps}`}</Layout.Item>
    </Layout>
  );
};
