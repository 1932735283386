import { Wizard } from '@vwfs-bronson/bronson-react';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { JOURNEY_STATE, useApplicationState } from '../../../App.state';
import { adobeDataLayer } from '../../../lib/adobe-analytics-data-layer/AdobeDataLayer';

import { RouterHelpers } from '../../../router/router-helpers';

export interface WizardStep {
  stepName: string;
  link: string;
  isActive: boolean;
  isDone: boolean;
  isDisabled?: boolean;
  additionalPaths?: string[];
  onClick?: (step: WizardStep) => void;
}

const getWizardSteps = (t: TFunction, currentJourneyStatus: JOURNEY_STATE): WizardStep[] => [
  {
    stepName: t('wizard.match-maker'),
    link: RouterHelpers.getMatchMakerQuestionPage1Path(),
    isActive: currentJourneyStatus <= JOURNEY_STATE.FINANCING,
    isDone: currentJourneyStatus > JOURNEY_STATE.FINANCING,
    isDisabled: currentJourneyStatus === JOURNEY_STATE.CONFIRMATION,
    onClick: () => adobeDataLayer.interactionWizardLinkClicked('Match Maker')
  },
  {
    stepName: t('wizard.dealer-selection'),
    link: RouterHelpers.getDealerSelectionPagePath(),
    isActive: currentJourneyStatus === JOURNEY_STATE.DEALER_SELECTION,
    isDone: currentJourneyStatus >= JOURNEY_STATE.SUMMARY,
    isDisabled: currentJourneyStatus === JOURNEY_STATE.CONFIRMATION,
    onClick: () => adobeDataLayer.interactionWizardLinkClicked('Dealer Selection')
  },
  {
    stepName: t('wizard.summary'),
    link: RouterHelpers.getSummaryPagePath(),
    isActive: currentJourneyStatus === JOURNEY_STATE.SUMMARY,
    isDone: currentJourneyStatus === JOURNEY_STATE.CONFIRMATION,
    onClick: () => adobeDataLayer.interactionWizardLinkClicked('Summary')
  },
  {
    stepName: t('wizard.confirmation'),
    link: RouterHelpers.getConfirmationPagePath(),
    isActive: currentJourneyStatus === JOURNEY_STATE.CONFIRMATION,
    isDone: currentJourneyStatus === JOURNEY_STATE.CONFIRMATION,
    onClick: () => adobeDataLayer.interactionWizardLinkClicked('Confirmation')
  }
];

const WizardContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('scaffolding');
  const {
    state: { currentJourneyStatus }
  } = useApplicationState();

  const wizardSteps = getWizardSteps(t, currentJourneyStatus);

  const activeIndex = wizardSteps.findIndex((step) => step.isActive === true);
  const activeStep = wizardSteps[activeIndex];
  const stepIndex = activeIndex + 1;
  const stepCount = wizardSteps.length;

  return (
    <Wizard
      data-wizard-collapse="m"
      collapsedText={t('wizard.collapsedTitle') || t('wizard.fallback')}
      collapsedCounter={activeStep ? `${stepIndex} / ${stepCount}` : ''}
      collapsedTitle={`${t('wizard.collapsedTitle')} ${
        activeStep ? `${stepIndex} / ${stepCount}` : ''
      }`}
    >
      {wizardSteps.map((step, index) => (
        <Wizard.Item
          key={index}
          active={step.isActive}
          disabled={step.isDisabled}
          noInteraction={step.isDisabled}
          done={step.isDone}
          onClick={() => {
            if (step.onClick) {
              step.onClick(step);
            }

            navigate(step.link);
          }}
        >
          {step.stepName}
        </Wizard.Item>
      ))}
    </Wizard>
  );
};

export default WizardContainer;
