import { Outlet } from 'react-router-dom';
import ErrorPage404 from '../pages/ErrorPages/ErrorPage404';
import { DefaultError } from '../lib/ui/Loaders/DefaultError';

export const WithValidTransaction = ({
  transactionId,
  isTransactionFinished
}: {
  transactionId: string | null;
  isTransactionFinished: boolean;
}): JSX.Element => {
  if (!transactionId) {
    return <ErrorPage404 />;
  } else if (isTransactionFinished) {
    return <DefaultError />;
  }

  return <Outlet />;
};
