import React, { useEffect } from 'react';

import { Step } from '../../../shared/interface';
import {
  MM4Fields,
  getFieldValidators
} from '../../../shared/validation-rules/match-maker-validator';
import { JOURNEY_STATE, useApplicationState } from '../../App.state';
import { Question } from '../../components/Questions';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import {
  EquipmentAndServices,
  Item
} from '../../lib/components/MatchMakerAnswersEditComponents/EquipmentAndServices';
import { FinancePlan } from '../../lib/components/MatchMakerAnswersEditComponents/FinancePlan';
import { MilesPerYear } from '../../lib/components/MatchMakerAnswersEditComponents/MilesPerYear';
import { mapOptionsToItems } from '../../lib/helpers/map-options-to-items-helpers';
import { RouterHelpers } from '../../router/router-helpers';
import { useCustomTranslation } from '../../utils/translations';
import { genericValidator } from '../MatchMakerHelper';
import MatchMakerQuestionWrapper from './MatchMakerQuestionWrapper';

interface OwnProps {
  isEditMode?: boolean;
}

export const MatchMakerQuestion4: React.FC<OwnProps> = ({ isEditMode }) => {
  const { t } = useCustomTranslation('match-maker');
  const { dispatch } = useApplicationState();

  useEffect(() => {
    adobeDataLayer.pageViewMatchMakerQuestionPage(4, 'Financial additional preferences');
  }, []);

  const additionalServices: Item[] = mapOptionsToItems(
    t,
    'question-pages.items.additional-services.options'
  );

  return (
    <MatchMakerQuestionWrapper
      currentStep={Step.MM4}
      pageTitle={t('question-pages.question-page-four.title')}
      isEditMode={isEditMode}
      currentPercentage={4}
      nextPage={RouterHelpers.getDealerSelectionPagePath()}
      previousPage={RouterHelpers.getMatchMakerQuestionPage3Path()}
      onGoToNextPage={() =>
        dispatch({
          type: 'updateJourneyStatus',
          journeyStatus: JOURNEY_STATE.DEALER_SELECTION,
          isEditMode: false
        })
      }
      validate={(values) => {
        const validationResult = genericValidator(
          getFieldValidators(t.sieToDu, MM4Fields, values),
          values
        );

        return validationResult;
      }}
      isCompleted={true}
    >
      {() => (
        <>
          <Question heading={t('question-pages.items.finance-plan.label')}>
            <FinancePlan />
          </Question>

          <hr className="u-mv-xlarge"></hr>

          <Question heading={t('question-pages.items.mileage.label')}>
            <MilesPerYear />
          </Question>

          <hr className="u-mv-xlarge"></hr>

          <Question heading={t('question-pages.items.additional-services.label')}>
            <EquipmentAndServices items={additionalServices} fieldName="additionalServices" />
          </Question>
        </>
      )}
    </MatchMakerQuestionWrapper>
  );
};
