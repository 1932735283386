import { Checkbox, Layout, InfoIcon } from '@vwfs-bronson/bronson-react';
import { useFormikContext } from 'formik';
import React from 'react';
import { MatchMakerAnswers } from '../../../../shared/types';

export interface Item {
  displayText: string;
  value: string;
  tooltip?: string;
}

function getFieldValue<T = string>(values: MatchMakerAnswers, key: keyof MatchMakerAnswers) {
  if (key in values) {
    const fieldValue = values[key] as unknown as T | Array<T>;

    return Array.isArray(fieldValue) ? fieldValue : [fieldValue];
  }

  return [];
}

export function EquipmentAndServices(props: { items: Item[]; fieldName: keyof MatchMakerAnswers }) {
  const { values, setFieldValue } = useFormikContext<MatchMakerAnswers>();
  const { fieldName } = props;

  return (
    <Layout>
      {props.items.map((item) => (
        <Layout.Item key={item.value} default={`6/${12}`} xs={'12/12'}>
          <Checkbox
            name={fieldName}
            value={item.value}
            checked={getFieldValue(values, fieldName)?.includes(item.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setFieldValue(fieldName, [...getFieldValue(values, fieldName), e.target.value]);
              } else {
                setFieldValue(
                  fieldName,
                  getFieldValue(values, fieldName)?.filter((item) => item !== e.target.value)
                );
              }
            }}
          >
            {item.displayText}
            {item.tooltip && (
              <InfoIcon
                icon="semantic-info"
                tippyProps={{
                  placement: 'top'
                }}
              >
                <div className="u-text-wrap" style={{ minWidth: '200px' }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.tooltip
                    }}
                  />
                </div>
              </InfoIcon>
            )}
          </Checkbox>
        </Layout.Item>
      ))}
    </Layout>
  );
}
