import { Tabs, Layout, Logo } from '@vwfs-bronson/bronson-react';
import { SelectableTileGroup, FieldError } from '../../../components';

import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { ICarSearchType, MatchMakerAnswers } from '../../../../shared/types';

import { carModelList, carTypeList, RenderForBrands } from '../../helpers/vehicle-helpers';
import { appConfig } from '../../../../config';
import { useCustomTranslation } from '../../../utils/translations';

export const CarType: React.FC = () => {
  const { t } = useCustomTranslation('match-maker');
  const { values, setFieldValue } = useFormikContext<MatchMakerAnswers>();
  const { errors } = useFormikContext<MatchMakerAnswers>();
  const { touched } = useFormikContext<MatchMakerAnswers>();

  useEffect(() => {
    if (appConfig.brandName !== 'audi') {
      setFieldValue('carSearchType', 'ByModel');
    }
  }, [setFieldValue]);

  return (
    <>
      {/* AUDI ONLY */}
      <RenderForBrands audi>
        <Tabs defaultSelected={ICarSearchType.BYTYPE}>
          <Tabs.Header>
            <Tabs.HeaderItem
              data-tr-component="button"
              data-tr-function="buttonExpandCarType"
              tabId={ICarSearchType.BYTYPE}
              onClick={() => {
                setFieldValue('carSearchType', 'ByType');
              }}
            >
              {t('question-pages.items.bodystyle.short-label')}
            </Tabs.HeaderItem>
            <Tabs.HeaderItem
              data-tr-component="button"
              data-tr-function="buttonExpandModels"
              tabId={ICarSearchType.BYMODEL}
              onClick={() => {
                setFieldValue('carSearchType', 'ByModel');
              }}
            >
              {t('question-pages.items.model.short-label')}
            </Tabs.HeaderItem>
          </Tabs.Header>
          <Tabs.Content>
            <Tabs.ContentItem tabId={ICarSearchType.BYTYPE} aria-labelledby={ICarSearchType.BYTYPE}>
              <SelectableTileGroup
                itemList={carTypeList(t)}
                itemColumnCount={[4, 12]}
                propName="typeOfCar"
                propObject={values.typeOfCar || []}
                maxChoices={3}
                maxChoicesWarning={t.sieToDu(
                  'match-maker:question-pages.items.bodystyle.validation.max'
                )}
              />
            </Tabs.ContentItem>
            <Tabs.ContentItem
              tabId={ICarSearchType.BYMODEL}
              aria-labelledby={ICarSearchType.BYMODEL}
            >
              <SelectableTileGroup
                itemList={carModelList.audi}
                propName="modelOfCar"
                itemColumnCount={[4, 12]}
                propObject={values.modelOfCar || []}
              />
            </Tabs.ContentItem>
          </Tabs.Content>
        </Tabs>
      </RenderForBrands>
      {/* OTHER BRANDS */}
      <RenderForBrands cupra seat vw vwn>
        <RenderForBrands cupra>
          <SelectableTileGroup
            itemList={carModelList.cupra}
            itemColumnCount={[4, 6, 12]}
            propName="modelOfCar"
            propObject={values.modelOfCar || []}
          />
        </RenderForBrands>
        <RenderForBrands vw>
          <SelectableTileGroup
            itemList={carModelList.vw}
            itemColumnCount={[4, 6, 12]}
            propName="modelOfCar"
            propObject={values.modelOfCar || []}
          />
        </RenderForBrands>
        <RenderForBrands vwn>
          <SelectableTileGroup
            itemList={carModelList.vwn}
            itemColumnCount={[4, 6, 12]}
            propName="modelOfCar"
            propObject={values.modelOfCar || []}
          />
        </RenderForBrands>
        <RenderForBrands seat>
          <Layout divider>
            <Layout.Item className="u-1/2 u-1/1@m">
              <Logo />
              <SelectableTileGroup
                itemList={carModelList.seat}
                itemColumnCount={[6, 6, 12]}
                propName="modelOfCar"
                propObject={values.modelOfCar || []}
                showErros={false}
                preserveValues={true}
              />
            </Layout.Item>
            <Layout.Item className="u-1/2 u-1/1@m">
              <Logo
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  scale: '1.25',
                  marginLeft: '20px'
                }}
                src="https://cdn.bronson.vwfs.io/cupra/v/11.12.0-beta/img/logo.svg"
              />
              <SelectableTileGroup
                itemList={carModelList.cupra}
                itemColumnCount={[6, 6, 12]}
                propName="modelOfCar"
                propObject={values.modelOfCar || []}
                showErros={false}
                preserveValues={true}
              />
            </Layout.Item>
          </Layout>
          <Layout>
            <Layout.Item>
              {touched.modelOfCar && errors.modelOfCar && (
                <FieldError
                  id="selectableTileGroup-modelOfCar"
                  errorMessage={(errors.modelOfCar as string) || ''}
                />
              )}
            </Layout.Item>
          </Layout>
        </RenderForBrands>
      </RenderForBrands>
    </>
  );
};
