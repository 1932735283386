/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';

export type Validator = (value: string | number | boolean | object) => string | null;

const validateSchemaAndCatchAllErrors = (
  schema: Yup.NumberSchema<number | undefined>,
  value: string | number | boolean | object
): string | null => {
  try {
    // We do not care about the validation result because we
    // will just return null to indicate validation succeeded.
    schema.validateSync(value);
    return null;
  } catch (e) {
    return (e as Yup.ValidationError).message;
  }
};

function validateNumberRange(
  errorMessageMandatory: string,
  errorMessageBelowMin: string,
  errorMessageAboveMax: string,
  min: number,
  max: number,
  typeError?: string
): Validator {
  return (value: string | number | boolean | object) => {
    // for optional field
    if (errorMessageMandatory === '' && !value) {
      return null;
    }

    const numberSchema = Yup.number()
      .typeError(typeError ?? errorMessageMandatory)
      .required(errorMessageMandatory)
      .min(min, errorMessageBelowMin)
      .max(max, errorMessageAboveMax);

    return validateSchemaAndCatchAllErrors(numberSchema, value);
  };
}

const validateNumberRangeMax = (
  errorMessageMandatory: string,
  errorMessageBelowMin: string,
  errorMessageAboveMax: string,
  min: number,
  max: number,
  typeError?: string
): Validator => {
  return (value: string | number | boolean | object) => {
    // for optional field
    if (errorMessageMandatory === '' && !value) {
      return null;
    }

    const numberSchema = Yup.number()
      .typeError(typeError ?? errorMessageMandatory)
      .required(errorMessageMandatory)
      .min(min, errorMessageBelowMin)
      .max(max, errorMessageAboveMax);

    return validateSchemaAndCatchAllErrors(numberSchema, value);
  };
};

function validateMaxAmount(
  errorMessageAboveMax: string,
  max: number,
  typeError?: string
): Validator {
  return (value: string | number | boolean | object) => {
    let errorMessage: string | null = null;

    const numberSchema = Yup.number()
      .typeError(typeError ?? errorMessageAboveMax)
      .max(max, errorMessageAboveMax);

    try {
      // We do not care about the validation result because we
      // will just return null to indicate validation succeeded.
      numberSchema.validateSync(value);
    } catch (e) {
      // It's not an error if the value is just empty/null/NaN/undefined, as it's not obligatory => set errorMessage to null
      if (!value) {
        errorMessage = null;
      } else {
        errorMessage = (e as Yup.ValidationError).message;
      }
    }

    return errorMessage;
  };
}

function validateRequired(errorMessage: string): Validator {
  return (value) => (value ? null : errorMessage);
}

function validateChoiceCountUpTo(
  errorMessage: string,
  errorMessageTooMany: string,
  maxCount: number
): Validator {
  return (value) => {
    if (!value) return errorMessage;

    // @ts-ignore
    const count = (value as []).length;
    if (count === 0) return errorMessage;
    if (count > maxCount) return errorMessageTooMany;

    return null;
  };
}

const defaultOptionalValueCheck = (value: any) => {
  return ['', undefined].includes(value);
};

function makeOptional<V extends Validator>(
  validator: V,
  emptyValueCheck?: (v: any) => boolean
): Validator {
  return (value) => {
    return (emptyValueCheck ? emptyValueCheck(value) : defaultOptionalValueCheck(value))
      ? null
      : validator(value);
  };
}

/**
 * @param condition Whether value is optional
 * @param optionalValueCheck Optional value validator. Defaults to `v => ['', undefined].includes(v)`
 */
function makeOptionalIf<V extends Validator>(
  condition: boolean,
  validator: V,
  optionalValueCheck?: (v: any) => boolean
): Validator {
  if (condition) {
    return (value) => {
      return makeOptional(validator, optionalValueCheck)(value);
    };
  }

  return (value) => validator(value);
}

export interface FieldValidators {
  [key: string]: Function[];
}

export {
  validateNumberRange,
  validateNumberRangeMax,
  validateRequired,
  validateChoiceCountUpTo,
  validateMaxAmount,
  makeOptional,
  makeOptionalIf
};
