import {
  CustomRangeSlider as BronsonReactCustomRangeSlider,
  Layout
} from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchMakerAnswers } from '../../../../../shared/types';
import { TrackableComponent } from '../../../../components';
import { MAX_MONTHLY_BUDGET, MIN_MONTHLY_BUDGET } from '../../../helpers/constants';

interface RangeProps {
  values: MatchMakerAnswers;
  setFieldValue: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const MonthlyBudgetSlider: React.FC<RangeProps> = ({ values, setFieldValue }) => {
  const { t } = useTranslation('match-maker');
  const [selectedValue, setSelectedValue] = useState<{
    expectedMonthlyPayment: number;
  }>({ expectedMonthlyPayment: values.expectedMonthlyPayment || MIN_MONTHLY_BUDGET });

  const handleOnChange = (value: string | number | (string | number)[]) => {
    setSelectedValue({ expectedMonthlyPayment: value as number });
    setFieldValue('expectedMonthlyPayment', value);
  };

  const formatNumber = (value: number): string => {
    return t('shared:installment', {
      amount: Math.round(value),
      currency: '€'
    });
  };

  return (
    <Layout center>
      <Layout.Item default="11/12">
        <TrackableComponent
          trackingComponent="CustomRangeSlider"
          trackingFunction={`sliderLabelMonthlyPayment${selectedValue}`}
        >
          <BronsonReactCustomRangeSlider
            range={{
              min: MIN_MONTHLY_BUDGET,
              max: MAX_MONTHLY_BUDGET
            }}
            value={values.expectedMonthlyPayment || MIN_MONTHLY_BUDGET}
            step={1}
            tooltips={{
              to: formatNumber
            }}
            onSet={(value: string | number | (string | number)[]) => {
              handleOnChange(Math.ceil(value as number));
            }}
          />
        </TrackableComponent>
      </Layout.Item>
    </Layout>
  );
};
