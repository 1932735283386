import React, { useEffect } from 'react';

import { Step } from '../../../shared/interface';
import {
  getFieldValidators,
  getMM1Fields
} from '../../../shared/validation-rules/match-maker-validator';
import { Question } from '../../components/Questions';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { CarType } from '../../lib/components/MatchMakerAnswersEditComponents/CarType';
import { FuelType } from '../../lib/components/MatchMakerAnswersEditComponents/FuelType';
import { VehicleCondition } from '../../lib/components/MatchMakerAnswersEditComponents/VehicleCondition';
import { RouterHelpers } from '../../router/router-helpers';
import { useCustomTranslation } from '../../utils/translations';
import { genericValidator } from '../MatchMakerHelper';
import MatchMakerQuestionWrapper from './MatchMakerQuestionWrapper';

interface OwnProps {
  isEditMode?: boolean;
}

export const MatchMakerQuestion1: React.FC<OwnProps> = ({ isEditMode }) => {
  const { t } = useCustomTranslation('match-maker');

  useEffect(() => {
    adobeDataLayer.pageViewMatchMakerQuestionPage(1, 'Vehicle preferences');
  }, []);

  return (
    <MatchMakerQuestionWrapper
      currentStep={Step.MM1}
      pageTitle={t('question-pages.question-page-one.title')}
      isEditMode={isEditMode}
      currentPercentage={1}
      nextPage={RouterHelpers.getMatchMakerQuestionPage2Path()}
      validate={(values) => {
        const validationResult = genericValidator(
          getFieldValidators(t.sieToDu, getMM1Fields(values.carSearchType), values),
          values
        );

        return validationResult;
      }}
    >
      {() => (
        <>
          <Question heading={t('question-pages.items.condition.label')}>
            <VehicleCondition />
          </Question>

          <hr className="u-mv-large"></hr>

          <Question heading={t('question-pages.items.bodystyle.label')}>
            <CarType />
          </Question>

          <hr className="u-mv-large"></hr>

          <Question heading={t('question-pages.items.fuel.label')}>
            <FuelType />
          </Question>
        </>
      )}
    </MatchMakerQuestionWrapper>
  );
};
