import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppProvider, useApplicationState } from './App.state';

import { QueryClient, QueryClientProvider } from 'react-query';
import { MatchMakerQuestion1 } from './MatchMaker/QuestionPages/MatchMakerQuestion1';
import { MatchMakerQuestion2 } from './MatchMaker/QuestionPages/MatchMakerQuestion2';
import { MatchMakerQuestion3 } from './MatchMaker/QuestionPages/MatchMakerQuestion3';
import { MatchMakerQuestion4 } from './MatchMaker/QuestionPages/MatchMakerQuestion4';
import { TransactionCompletedNotification } from './lib/components/TransactionCompletedNotification';
import { Confirmation } from './pages/Confirmation';
import { CustomerInformation } from './pages/CustomerInformation';
import { DealerSelection } from './pages/DealerSelection';
import ErrorPage404 from './pages/ErrorPages/ErrorPage404';
import MasterPage from './pages/MasterPage';
import { SummaryPage } from './pages/SummaryPage';
import WizardContainer from './pages/components/Wizard/WizardContainer';
import EntryPoint from './router/EntryPoint';
import { WithValidTransaction } from './router/WithValidTransaction';
import { RouterHelpers } from './router/router-helpers';
import { TransactionStatusResponseBody, getTransactionStatus } from './api/transaction';
import { TransactionStatus } from '@bus/models';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 0 } }
});

const redirectToFirstStep = () => {
  window.location.hash = '';
};

function App(): JSX.Element {
  const [transactionId, setTransactionId] = useState('');
  const [isTransactionFinished, setIsTransactionFinished] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setTransactionId(queryParams.get('transactionId') || '');
  }, []);

  useEffect(() => {
    if (transactionId) {
      redirectToFirstStep();

      getTransactionStatus(transactionId)
        .then((transactionStatus: TransactionStatusResponseBody) => {
          setIsTransactionFinished(transactionStatus.status === TransactionStatus.COMPLETED);
        })
        .catch(() => {});
    }
  }, [transactionId]);

  const {
    state: { matchMakerAnswers, legalEntity }
  } = useApplicationState();

  return (
    <AppProvider>
      <MasterPage>
        <QueryClientProvider client={queryClient}>
          <WizardContainer />
          <TransactionCompletedNotification />

          <Routes>
            <Route
              path={RouterHelpers.getEntryPointPath()}
              element={
                (matchMakerAnswers.isCompleted && (
                  <Navigate to={RouterHelpers.getSummaryPagePath()} />
                )) ||
                (!legalEntity && <Navigate to={RouterHelpers.get499ErrorPagePath()} />) || (
                  <EntryPoint />
                )
              }
            />
            <Route
              element={
                <WithValidTransaction
                  transactionId={transactionId}
                  isTransactionFinished={isTransactionFinished}
                />
              }
            >
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage1Path()}
                element={<MatchMakerQuestion1 isEditMode={false} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage2Path()}
                element={<MatchMakerQuestion2 isEditMode={false} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage3Path()}
                element={<MatchMakerQuestion3 isEditMode={false} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage4Path()}
                element={<MatchMakerQuestion4 isEditMode={false} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage1EditPath()}
                element={<MatchMakerQuestion1 isEditMode={true} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage2EditPath()}
                element={<MatchMakerQuestion2 isEditMode={true} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage3EditPath()}
                element={<MatchMakerQuestion3 isEditMode={true} />}
              />
              <Route
                path={RouterHelpers.getMatchMakerQuestionPage4EditPath()}
                element={<MatchMakerQuestion4 isEditMode={true} />}
              />
              <Route
                path={RouterHelpers.getDealerSelectionPagePath()}
                element={<DealerSelection />}
              />
              <Route
                path={RouterHelpers.getDealerSelectionEditPagePath()}
                element={<DealerSelection isEditMode={true} />}
              />
              <Route
                path={RouterHelpers.getPersonalDetailsPath()}
                element={<CustomerInformation />}
              />
              <Route path={RouterHelpers.getConfirmationPagePath()} element={<Confirmation />} />
              <Route path={RouterHelpers.getSummaryPagePath()} element={<SummaryPage />} />
            </Route>
            <Route path="*" element={<ErrorPage404 noId={!transactionId} noLegalEntity={!legalEntity} />} />
          </Routes>
        </QueryClientProvider>
      </MasterPage>
    </AppProvider>
  );
}

export default App;
