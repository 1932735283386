import {
  Button,
  ButtonContainer,
  ContentSection,
  DataOverview,
  DescriptionList,
  PageWrap,
  SectionHeading
} from '@vwfs-bronson/bronson-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TrackableComponent } from '../../components';

import {
  EditableSummarySection,
  FlowType,
  JOURNEY_STATE,
  useApplicationState
} from '../../App.state';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { RenderForBrands, getFieldSummaryText } from '../../lib/helpers/vehicle-helpers';
import { RouterHelpers } from '../../router/router-helpers';

import { TFunction } from 'i18next';
import { Step } from '../../../shared/interface';
import { CustomerContactDetails, ICarSearchType, MatchMakerAnswers } from '../../../shared/types';
import { submitAnswers } from '../../api/submitAnswers';
import { loadingOrError } from '../../lib/ui/Loaders/GraphqlLoadingUtility';
import { kwToPs } from '../../utils/kwToPs';

const getTypeOfGearsDisplayData = (matchMakerAnswers: MatchMakerAnswers, t: TFunction) => {
  if (matchMakerAnswers.typeOfGears && matchMakerAnswers.typeOfGears.length) {
    return getFieldSummaryText(matchMakerAnswers, 'typeOfGears', t);
  }

  return t('gears-type-empty');
};

const getContactPreferenceDisplayData = (
  customerInformation: CustomerContactDetails,
  t: TFunction
) => {
  return t(`items.contact-preference.${customerInformation.contactPreference}`);
};

export function MatchMakerResult(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<Error>();
  const { t } = useTranslation('summary-page');
  const { state, dispatch } = useApplicationState();
  const navigate = useNavigate();

  useEffect(() => {
    adobeDataLayer.pageViewMatchMakerSummary();
    // We need to use the empty array to avoid loops
    // eslint-disable-next-line
  }, []);

  const { matchMakerAnswers, dealer, transactionId, customerInformation } = state;

  if (isLoading || submitError) return loadingOrError(isLoading, submitError);

  const performanceValue = t('items.performance-format', {
    minKW: matchMakerAnswers.minPower,
    minPS: kwToPs(matchMakerAnswers.minPower ?? 0),
    maxKW: matchMakerAnswers.maxPower,
    maxPS: kwToPs(matchMakerAnswers.maxPower ?? 0)
  });

  const expectedMonthlyPaymentValue = t('items.budget-format', {
    expectedMonthlyPayment: matchMakerAnswers.expectedMonthlyPayment
  });

  const equipmentText =
    matchMakerAnswers.specialEquipments && matchMakerAnswers.specialEquipments.length > 0
      ? matchMakerAnswers.specialEquipments?.join(', ')
      : t('items.equipment-standard');

  const servicesText =
    matchMakerAnswers.additionalServices && matchMakerAnswers.additionalServices.length > 0
      ? matchMakerAnswers.additionalServices?.join(', ')
      : t('items.services-standard');

  const financeText = getFieldSummaryText(matchMakerAnswers, 'financePlan', t)
    ? getFieldSummaryText(matchMakerAnswers, 'financePlan', t)
    : t('items.finance-standard');

  const dealerAddress = (() => {
    return `${dealer.street.replace(/,/g, '')} ${dealer.houseNumber}, ${dealer.zip} ${dealer.city}`;
  })();

  const canShowFieldValue = (
    editedSections: Array<EditableSummarySection>,
    summarySectionName: EditableSummarySection,
    selectedFlowType: FlowType
  ): boolean => {
    return selectedFlowType != FlowType.SHORT || editedSections.includes(summarySectionName);
  };

  return (
    <ContentSection>
      <PageWrap>
        <SectionHeading level="1" subtitle={t('subtitle')}>
          {t('title')}
        </SectionHeading>

        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('characteristics-title')}
            buttonLabel={t('shared:edit-button')}
            buttonProps={{
              onClick: () => {
                adobeDataLayer.interactionMatchMakerSummaryEditButtonClicked(
                  'Condition & Body & Transmission'
                );
                dispatch({ type: 'updateEditStatus', isEditMode: true });
                dispatch({ type: 'markEditedSection', sectionName: 'characteristics' });
                navigate(RouterHelpers.getMatchMakerQuestionPage1EditPath());
              }
            }}
          >
            <DescriptionList grid>
              <DescriptionList.Group termText={t('items.car-condition-label')}>
                <DescriptionList.Detail>
                  {getFieldSummaryText(matchMakerAnswers, 'condition', t)}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              {matchMakerAnswers.carSearchType === ICarSearchType.BYTYPE ? (
                <DescriptionList.Group termText={t('items.bodystyle-label')}>
                  <DescriptionList.Detail>
                    {getFieldSummaryText(matchMakerAnswers, 'typeOfCar', t)}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
              ) : (
                <DescriptionList.Group termText={t('items.model-label')}>
                  <DescriptionList.Detail>
                    {getFieldSummaryText(matchMakerAnswers, 'modelOfCar', t)}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
              )}
              <DescriptionList.Group termText={t('items.fuel-label')}>
                <DescriptionList.Detail>
                  {getFieldSummaryText(matchMakerAnswers, 'fuelTypes', t)}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>
        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('finance-title')}
            buttonLabel={t('shared:edit-button')}
            buttonProps={{
              onClick: () => {
                adobeDataLayer.interactionMatchMakerSummaryEditButtonClicked('Financial');
                dispatch({ type: 'updateEditStatus', isEditMode: true });
                dispatch({ type: 'markEditedSection', sectionName: 'finance' });
                navigate(RouterHelpers.getMatchMakerQuestionPage2EditPath());
              }
            }}
          >
            <DescriptionList grid>
              <DescriptionList.Group termText={t('items.budget-label')}>
                <DescriptionList.Detail>{expectedMonthlyPaymentValue}</DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.deposit-label')}>
                <DescriptionList.Detail>
                  {t('shared:currency', {
                    value: matchMakerAnswers.additionalDeposit ?? 0
                  })}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.duration-label')}>
                <DescriptionList.Detail>
                  {t('shared:duration', {
                    months: matchMakerAnswers.expectedDuration
                  })}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>
        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('features-title')}
            buttonLabel={t('shared:edit-button')}
            buttonProps={{
              onClick: () => {
                adobeDataLayer.interactionMatchMakerSummaryEditButtonClicked('Fuel & Performance');
                dispatch({ type: 'updateEditStatus', isEditMode: true });
                dispatch({ type: 'markEditedSection', sectionName: 'features' });
                navigate(RouterHelpers.getMatchMakerQuestionPage3EditPath());
              }
            }}
          >
            <DescriptionList grid>
              <DescriptionList.Group termText={t('items.gear-label')}>
                <DescriptionList.Detail>
                  {getTypeOfGearsDisplayData(matchMakerAnswers, t)}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.performance-label')}>
                <DescriptionList.Detail>
                  {canShowFieldValue(state.editedSections, 'features', state.flowType)
                    ? performanceValue
                    : t('no-value')}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.equipment-label')}>
                <DescriptionList.Detail>{equipmentText}</DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>

        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('finance-title-additional-info')}
            buttonLabel={t('shared:edit-button')}
            buttonProps={{
              onClick: () => {
                adobeDataLayer.interactionMatchMakerSummaryEditButtonClicked('Financial');
                dispatch({ type: 'updateEditStatus', isEditMode: true });
                dispatch({ type: 'markEditedSection', sectionName: 'additional-info' });
                navigate(RouterHelpers.getMatchMakerQuestionPage4EditPath());
              }
            }}
          >
            <DescriptionList grid>
              <DescriptionList.Group termText={t('items.finance-plan-label')}>
                <DescriptionList.Detail>{financeText}</DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.services-label')}>
                <DescriptionList.Detail>{servicesText}</DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.mileage-label')}>
                <DescriptionList.Detail>
                  {canShowFieldValue(state.editedSections, 'additional-info', state.flowType)
                    ? t('items.mileage-format', { value: matchMakerAnswers.milesPerYear })
                    : t('no-value')}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>

        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('dealer-title')}
            buttonLabel={t('shared:edit-button')}
            buttonProps={{
              onClick: () => {
                adobeDataLayer.interactionMatchMakerSummaryEditButtonClicked('Dealer Selection');
                dispatch({ type: 'updateEditStatus', isEditMode: true });
                dispatch({ type: 'markEditedSection', sectionName: 'dealer' });
                navigate(RouterHelpers.getDealerSelectionEditPagePath());
              }
            }}
          >
            <DescriptionList grid>
              <DescriptionList.Group termText={t('items.dealer-name-label')}>
                <DescriptionList.Detail>{dealer.name}</DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.dealer-address-label')}>
                <DescriptionList.Detail>{dealerAddress}</DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>

        <ContentSection.ComponentWrapper>
          <DataOverview
            title={t('personal-details-title')}
            buttonLabel={t('shared:edit-button')}
            buttonProps={{
              onClick: () => {
                adobeDataLayer.interactionMatchMakerSummaryEditButtonClicked(
                  'Customer Information'
                );
                dispatch({ type: 'updateEditStatus', isEditMode: true });
                dispatch({ type: 'markEditedSection', sectionName: 'personal-details' });
                navigate(RouterHelpers.getPersonalDetailsPath());
              }
            }}
          >
            <DescriptionList grid>
              <DescriptionList.Group termText={t('items.name-label')}>
                <DescriptionList.Detail>
                  {`${customerInformation.firstName} ${customerInformation.lastName}`}
                </DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.phone-label')}>
                <DescriptionList.Detail>{customerInformation.phoneNumber}</DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.mail-label')}>
                <DescriptionList.Detail>{customerInformation.email}</DescriptionList.Detail>
              </DescriptionList.Group>

              <DescriptionList.Group termText={t('items.contact-preference.label')}>
                <DescriptionList.Detail>
                  {getContactPreferenceDisplayData(customerInformation, t)}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </DataOverview>
        </ContentSection.ComponentWrapper>

        <RenderForBrands seat cupra>
          <ContentSection.ComponentWrapper>
            {t('legal-text')}&nbsp;
            <a href={t('legal-link')} target="_blank" rel="noreferrer">
              {t('legal-link')}
            </a>
            .
          </ContentSection.ComponentWrapper>
        </RenderForBrands>

        <ContentSection.ComponentWrapper>
          <ButtonContainer nav>
            <TrackableComponent
              trackingComponent="button"
              trackingFunction="buttonBackToDealerSearch"
            >
              <Button secondary={true} type="button" onClick={() => navigate(-1)}>
                {t('shared:back-button')}
              </Button>
            </TrackableComponent>
            <TrackableComponent trackingComponent="button" trackingFunction="buttonContactMe">
              <Button
                onClick={() => {
                  setIsLoading(true);

                  submitAnswers({
                    transactionId,
                    matchMakerAnswers,
                    selectedDealer: dealer.id,
                    contactDetails: customerInformation,
                    currentStep: Step.FINAL_CONFIRMATION,
                    isEditMode: false
                  })
                    .then((data) => {
                      setIsLoading(false);
                      dispatch({
                        type: 'updateState',
                        state: {
                          ...state,
                          transactionId: data,
                          currentJourneyStatus: JOURNEY_STATE.CONFIRMATION
                        }
                      });
                      navigate(RouterHelpers.getConfirmationPagePath());
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      setSubmitError(e);
                    });
                }}
              >
                {t('submit-button')}
              </Button>
            </TrackableComponent>
          </ButtonContainer>
        </ContentSection.ComponentWrapper>
      </PageWrap>
    </ContentSection>
  );
}
