import { ErrorMessage } from '@vwfs-bronson/bronson-react';
import { bronsonIconSwitchEmpty } from '../../lib/helpers/icon-helpers';

interface OwnProps {
  errorMessage: string;
  id?: string;
}

export const FieldError: React.FunctionComponent<OwnProps> = ({
  errorMessage,
  id
}): JSX.Element | null => {
  return errorMessage ? (
    <ErrorMessage
      id={id}
      className={`u-mb-none ${bronsonIconSwitchEmpty('', '', '', 'u-mt-small')}`}
    >
      {errorMessage}
    </ErrorMessage>
  ) : null;
};
