import { useQuery, UseQueryOptions } from 'react-query';
import { appConfig } from '../../config';
import { Dealer } from '../../shared/types';
import axios from './axios';


const getDealers = async (input: string) => {
  const response = await axios.get(`/api/${appConfig.brandName}/dealers`, {
    params: { search: input }
  }).catch(() => {
    return {
      data: { dealers: [] }
    };
  });
  return response.data;
};

export const getDealer = async (input: string) => {
  const { data } = await axios
    .get('/api/dealer', {
      params: { dealerId: input }
    })
    .catch((e) => {
      if (e.message.includes('404')) {
        return { data: undefined };
      }

      throw e;
    });

  return data;
};

export function useGetDealers(
  searchParameter: string,
  options?: UseQueryOptions<unknown, unknown, Dealer[], string[]> | undefined
) {
  return useQuery(['getDealers', searchParameter], () => getDealers(searchParameter), options);
}

export function useGetDealer(
  searchParameter: string,
  options?: UseQueryOptions<unknown, unknown, Dealer, string[]> | undefined
) {
  return useQuery(['getSingleDealer', searchParameter], () => getDealer(searchParameter), options);
}
