import { Header as BronsonReactHeader, Logo } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useApplicationState } from '../../../App.state';
import { adobeDataLayer } from '../../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { RouterHelpers } from '../../../router/router-helpers';

const HeaderContainer: React.FC = () => {
  const { state } = useApplicationState();
  const { t } = useTranslation('shared');

  return (
    <BronsonReactHeader
      logo={
        <Logo
          specificLogo={process.env.REACT_APP_BRAND === 'vwn' ? 'commercial-vehicle-de' : undefined}
          ariaLabel={process.env.REACT_APP_BRAND}
          title={t('shared:logo-text')}
          onClick={() => {
            adobeDataLayer.interactionLogoClicked();
            // we cannot use navigate method here as we are using hash routing in this app
            // but here we need to redirect user to a Landing page which created on AEM.
            window.open(
              RouterHelpers.getLandingPageUrl(
                state.legalEntity === '004180' ? 'leasing' : 'financing'
              ),
              '_self'
            );
          }}
        />
      }
    />
  );
};

export default HeaderContainer;
