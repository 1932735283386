import { FormikErrors } from 'formik';
import { MatchMakerAnswers } from '../../shared/types';

export const genericValidator = (
  fieldValidators: { [key: string]: Function[] },
  values: MatchMakerAnswers
): FormikErrors<MatchMakerAnswers> => {
  const errors: FormikErrors<MatchMakerAnswers> = {};

  Object.keys(fieldValidators).forEach((fieldName) => {
    const validators = fieldValidators[fieldName] || [];
    //@ts-ignore - type of values has no index signature
    const value = values[fieldName];

    //@ts-ignore - type of values has no index signature
    validators.every((validatorFun: (val: string | number | boolean | object) => string | null) => {
      const validationResult = validatorFun(value);
      if (validationResult !== null) {
        //@ts-ignore
        errors[fieldName] = validationResult;
        return false;
      }
      return true;
    });
  });

  return errors;
};
