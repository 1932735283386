import { TFunction } from 'i18next';
import { appConfig } from '../../../config';
import audiAvant from '../../../resources/assets/icons/audi/avant.svg';
import audiCabriolet from '../../../resources/assets/icons/audi/cabriolet.svg';
import audiCoupe from '../../../resources/assets/icons/audi/coupe.svg';
import audiSaloon from '../../../resources/assets/icons/audi/saloon.svg';
import audiSportback from '../../../resources/assets/icons/audi/sportback.svg';
import audiSuv from '../../../resources/assets/icons/audi/suv.svg';
import seatEstate from '../../../resources/assets/icons/seat/estate.svg';
import seatHatchback from '../../../resources/assets/icons/seat/hatchback.svg';
import seatMpv from '../../../resources/assets/icons/seat/mpv.svg';
import seatSuv from '../../../resources/assets/icons/seat/suv.svg';
import skodaEstate from '../../../resources/assets/icons/skoda/estate.svg';
import skodaHatchback from '../../../resources/assets/icons/skoda/hatchback.svg';
import skodaSaloon from '../../../resources/assets/icons/skoda/saloon.svg';
import skodaSuv from '../../../resources/assets/icons/skoda/suv.svg';
import vwMPV from '../../../resources/assets/icons/vw/MPV.svg';
import vwSuv from '../../../resources/assets/icons/vw/SUV.svg';
import vwEstate from '../../../resources/assets/icons/vw/estate.svg';
import vwHatchback from '../../../resources/assets/icons/vw/hatchback.svg';
import vwSaloon from '../../../resources/assets/icons/vw/saloon.svg';
import { IFuelType, IMatchType, ITransmissionType, LocalBrands } from '../../../shared/types';
import { MatchType } from '../types/MatchType';
import { QuestionItem, SelectableTileCheckboxButtonGroupQuestionItem } from '../types/QuestionItem';
import { Icons } from './icon-helpers';
import { imgSwitch } from './img-helpers';

import { Children } from 'react';
import { FinancePlan } from '../../../custom-types.d';

const isAvailableIn = (brands: LocalBrands[]) => brands.includes(appConfig.brandName);

export function carTypeList(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t('match-maker:question-pages.items.bodystyle.options.one'),
      value: t('match-maker:question-pages.items.bodystyle.options.one'),
      description: t('match-maker:question-pages.items.bodystyle.options.one-example'),
      img: imgSwitch(audiSportback, vwHatchback, skodaHatchback, seatHatchback),
      icon: '',
      trackingName: 'checkboxHatchback',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.bodystyle.options.two'),
      value: t('match-maker:question-pages.items.bodystyle.options.two'),
      description: t('match-maker:question-pages.items.bodystyle.options.two-example'),
      img: imgSwitch(audiSaloon, vwSaloon, skodaSaloon),
      icon: '',
      trackingName: 'checkboxSaloon',
      availableIn: isAvailableIn(['audi'])
    },
    {
      displayName: t('match-maker:question-pages.items.bodystyle.options.three'),
      value: t('match-maker:question-pages.items.bodystyle.options.three'),
      description: t('match-maker:question-pages.items.bodystyle.options.three-example'),
      img: imgSwitch(audiAvant, vwEstate, skodaEstate, seatEstate),
      icon: '',
      trackingName: 'checkboxEstate',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.bodystyle.options.four'),
      value: t('match-maker:question-pages.items.bodystyle.options.four'),
      description: t('match-maker:question-pages.items.bodystyle.options.four-example'),
      img: imgSwitch(audiCoupe),
      icon: '',
      trackingName: 'checkboxCOUPE',
      availableIn: isAvailableIn(['audi'])
    },
    {
      displayName: t('match-maker:question-pages.items.bodystyle.options.five'),
      value: t('match-maker:question-pages.items.bodystyle.options.five'),
      description: t('match-maker:question-pages.items.bodystyle.options.five-example'),
      img: imgSwitch(audiSuv, vwSuv, skodaSuv, seatSuv),
      icon: '',
      trackingName: 'checkboxSUV',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.bodystyle.options.six'),
      value: t('match-maker:question-pages.items.bodystyle.options.six'),
      description: t('match-maker:question-pages.items.bodystyle.options.six-example'),
      img: imgSwitch(audiCabriolet, vwMPV, '', seatMpv),
      icon: '',
      trackingName: 'checkboxMPV',
      availableIn: true
    }
  ];
}

const defineModel = (name: string, icon?: string, value?: string | number) => {
  value = String(value ? value : name);

  return {
    displayName: name,
    availableIn: true,
    value,
    icon: icon || '',
    trackingName: `checkbox${value}`
  };
};

export const carModelList = ((): Record<LocalBrands, QuestionItem<string>[]> => {
  return {
    audi: [
      defineModel('A1'),
      defineModel('A3'),
      defineModel('A4'),
      defineModel('A5'),
      defineModel('A6'),
      defineModel('A7'),
      defineModel('A8'),
      defineModel('Q2'),
      defineModel('Q3'),
      defineModel('Q5'),
      defineModel('Q7'),
      defineModel('Q8'),
      defineModel('TT'),
      defineModel('R8'),
      defineModel('Q4 e-tron'),
      defineModel('Q6 e-tron'),
      defineModel('Q8 e-tron'),
      defineModel('e-tron GT')
    ],
    seat: [
      defineModel('SEAT Ibiza'),
      defineModel('SEAT Arona'),
      defineModel('SEAT Leon'),
      defineModel('SEAT Leon Sportstourer'),
      defineModel('SEAT Ateca')
    ],
    cupra: [
      defineModel('CUPRA Formentor'),
      defineModel('CUPRA Leon'),
      defineModel('CUPRA Leon Sportstourer'),
      defineModel('CUPRA Ateca'),
      defineModel('CUPRA Born'),
      defineModel('CUPRA Tavascan'),
      defineModel('CUPRA Terramar')
    ],
    vw: [
      defineModel('up!'),
      defineModel('Der Polo'),
      defineModel('Der Taigo'),
      defineModel('Der T-Cross'),
      defineModel('Der ID.3'),
      defineModel('Der ID.4'),
      defineModel('Der ID.5'),
      defineModel('Der ID.7'),
      defineModel('Der Golf'),
      defineModel('Der Golf Variant'),
      defineModel('Der Passat Variant'),
      defineModel('Der T-Roc'),
      defineModel('Das T-Roc Cabriolet'),
      defineModel('Der Touran'),
      defineModel('Der Tiguan'),
      defineModel('Der Tiguan Allspace'),
      defineModel('Der Arteon'),
      defineModel('Der Arteon Shooting Brake'),
      defineModel('Der Touareg')
    ],
    vwn: [
      defineModel('ID.Buzz'),
      defineModel('ID.Buzz Cargo'),
      defineModel('Amarok'),
      defineModel('Caddy'),
      defineModel('Caddy California'),
      defineModel('Caddy Kombi'),
      defineModel('Caddy Cargo'),
      defineModel('Multivan'),
      defineModel('Multivan California'),
      defineModel('Transporter Kasten'),
      defineModel('Transporter Kombi'),
      defineModel('Crafter Kastenwagen'),
      defineModel('Crafter Pritschenwagen'),
      defineModel('Crafter Fahrgestell'),
      defineModel('Grand California')
    ]
  };
})();

export function transmissionList(t: TFunction): QuestionItem<ITransmissionType>[] {
  return [
    {
      displayName: t('match-maker:question-pages.items.gears.options.one'),
      value: ITransmissionType.Manual,
      icon: Icons.TransmissionManual,
      trackingName: 'checkboxManual',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.gears.options.two'),
      value: ITransmissionType.Automatic,
      icon: Icons.TransmissionAutomatic,
      trackingName: 'checkboxAutomatic',
      availableIn: true
    }
  ];
}

export function fuelList(t: TFunction): QuestionItem<IFuelType>[] {
  return [
    {
      displayName: t('match-maker:question-pages.items.fuel.options.one'),
      value: IFuelType.Petrol,
      icon: Icons.FuelPetrol,
      trackingName: 'checkboxPetrol',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.fuel.options.two'),
      value: IFuelType.Diesel,
      icon: Icons.FuelDiesel,
      trackingName: 'checkboxDiesel',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.fuel.options.three'),
      value: IFuelType.NaturalGas,
      icon: Icons.FuelNaturalGas,
      trackingName: 'checkboxNaturalGas',
      availableIn: isAvailableIn(['audi', 'seat', 'vwn'])
    },
    {
      displayName: t('match-maker:question-pages.items.fuel.options.four'),
      value: IFuelType.Hybrid,
      icon: Icons.FuelHybrid,
      trackingName: 'checkboxHybrid',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.fuel.options.five'),
      value: IFuelType.Electric,
      icon: Icons.FuelElectric,
      trackingName: 'checkboxElectric',
      availableIn: true
    }
  ];
}

export function financePlanList(
  t: TFunction
): SelectableTileCheckboxButtonGroupQuestionItem<FinancePlan>[] {
  return [
    {
      displayName: t('match-maker:question-pages.items.finance-plan.options.one'),
      summaryDisplayName: t('match-maker:question-pages.items.finance-plan.options.summaryOne'),
      description: (
        <ul className="c-ul u-mt-xsmall u-text-left u-font-size-fs-1">
          <li>
            {t('match-maker:question-pages.items.finance-plan.options.one-description.item-one')}
          </li>
          <li>
            {t('match-maker:question-pages.items.finance-plan.options.one-description.item-two')}
          </li>
        </ul>
      ),
      value: FinancePlan.Leasing,
      icon: '',
      trackingName: 'checkboxLeasing',
      availableIn: true,
      hasVideo: appConfig.brandName === 'audi'
    },
    {
      displayName: t('match-maker:question-pages.items.finance-plan.options.two'),
      summaryDisplayName: t('match-maker:question-pages.items.finance-plan.options.summaryTwo'),
      description: (
        <ul className="c-ul u-mt-xsmall u-text-left u-font-size-fs-1">
          <li>
            {t('match-maker:question-pages.items.finance-plan.options.two-description.item-one')}
          </li>
          <li>
            {t('match-maker:question-pages.items.finance-plan.options.two-description.item-two')}
          </li>
        </ul>
      ),
      value: FinancePlan.Credit,
      icon: '',
      trackingName: 'checkboxCredit',
      availableIn: true,
      hasVideo: appConfig.brandName === 'audi'
    }
  ];
}

export function conditionList(t: TFunction): QuestionItem<string>[] {
  return [
    {
      displayName: t('match-maker:question-pages.items.condition.options.one'),
      value: 'NEW',
      icon: '',
      trackingName: 'checkboxConditionNew',
      availableIn: true
    },
    {
      displayName: t('match-maker:question-pages.items.condition.options.two'),
      value: 'USED',
      icon: '',
      trackingName: 'checkboxConditionUsed',
      availableIn: true
    }
  ];
}

function fieldOptions(t: TFunction): {
  [key: string]:
    | QuestionItem<string | number>[]
    | SelectableTileCheckboxButtonGroupQuestionItem<string | number>[];
} {
  return {
    typeOfCar: carTypeList(t),
    modelOfCar: [
      ...carModelList.audi,
      ...carModelList.seat,
      ...carModelList.cupra,
      ...carModelList.vw,
      ...carModelList.vwn
    ],
    typeOfGears: transmissionList(t),
    fuelTypes: fuelList(t),
    condition: conditionList(t),
    financePlan: financePlanList(t)
  };
}

export const getFieldSummaryText = (
  objectContainsField: object,
  fieldName: string,
  t: TFunction
): string => {
  const questionItemList:
    | QuestionItem<string | number>[]
    | SelectableTileCheckboxButtonGroupQuestionItem<string | number>[] = fieldOptions(t)[fieldName];
  const results: string[] = [];

  // @ts-ignore
  objectContainsField[fieldName].forEach((value) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const displayItem = (questionItemList as QuestionItem<any>[]).find(
      (item) => item.value === value
    );

    if (displayItem && displayItem.summaryDisplayName) {
      results.push(displayItem.summaryDisplayName);
    } else if (displayItem) {
      results.push(displayItem.displayName);
    }
  });

  return results.join(', ');
};

export const getTransmissionTypeText = (
  transmissionType: ITransmissionType,
  t: TFunction
): string => {
  return getFieldSummaryText({ typeOfGears: [transmissionType] }, 'typeOfGears', t);
};

export const getFuelTypeText = (fuelType: IFuelType, t: TFunction): string => {
  return getFieldSummaryText({ fuelTypes: [fuelType] }, 'fuelTypes', t);
};

export const mapToTypeOfMatch = (matchType: IMatchType): MatchType => {
  switch (matchType) {
    case IMatchType.Best:
      return MatchType.Best;
    case IMatchType.Great:
      return MatchType.Great;
    case IMatchType.Good:
      return MatchType.Good;
    case IMatchType.Fair:
      return MatchType.Fair;
    default:
      return MatchType.NoMatch;
  }
};

/**
 * - Render children for brands specified in props only.
 * - Return `null` otherwise.
 */
export const RenderForBrands: React.FC<Partial<Record<LocalBrands, boolean>>> = ({
  audi,
  seat,
  cupra,
  vw,
  vwn,
  children
}) => {
  const allowedBrands = ((): Array<LocalBrands> => {
    const _brands = [] as Array<LocalBrands>;

    if (audi) _brands.push('audi');
    if (seat) _brands.push('seat');
    if (cupra) _brands.push('cupra');
    if (vw) _brands.push('vw');
    if (vwn) _brands.push('vwn');

    if (!_brands.length) _brands.push('audi'); // default

    return _brands;
  })();

  if (!allowedBrands.includes(appConfig.brandName)) return null;
  if (!children) return null;

  const count = Children.count(children);

  return count > 1 ? <>{children}</> : (children as JSX.Element);
};
