import { ErrorMessage } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, NumericInput, TrackableComponent } from '../../../components';

export function AdditionalDepositInput(): JSX.Element {
  const { t } = useTranslation('match-maker');

  return (
    <>
      <TrackableComponent trackingFunction="inputboxAdditionalDeposit" trackingComponent="inputbox">
        <NumericInput
          fieldName="additionalDeposit"
          placeholder={t('question-pages.items.additional-deposit.input-field.placeholder')}
          addonText={t('question-pages.items.additional-deposit.input-field.addon')}
        />
      </TrackableComponent>
      <ErrorMessage name="additionalDeposit">
        {(msg) => <FieldError id="additionalDeposit" errorMessage={msg} />}
      </ErrorMessage>
    </>
  );
}
