import React, { useEffect } from 'react';

import { InfoIcon } from '@vwfs-bronson/bronson-react';
import _ from 'lodash';
import { Step } from '../../../shared/interface';
import {
  getFieldValidators,
  MM2Fields
} from '../../../shared/validation-rules/match-maker-validator';
import { initialState, JOURNEY_STATE } from '../../App.state';
import { Question } from '../../components/Questions';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { AdditionalDepositInfo } from '../../lib/components/MatchMakerAnswersEditComponents/AdditionalDepositInfo';
import { ExpectedDuration } from '../../lib/components/MatchMakerAnswersEditComponents/ExpectedDuration';
import { ExpectedMonthlyPayment } from '../../lib/components/MatchMakerAnswersEditComponents/ExpectedMonthlyPayment';
import { RouterHelpers } from '../../router/router-helpers';
import { useCustomTranslation } from '../../utils/translations';
import { genericValidator } from '../MatchMakerHelper';
import MatchMakerQuestionWrapper from './MatchMakerQuestionWrapper';

interface OwnProps {
  isEditMode?: boolean;
}

export const MatchMakerQuestion2: React.FC<OwnProps> = ({ isEditMode }) => {
  const { t } = useCustomTranslation('match-maker');

  useEffect(() => {
    adobeDataLayer.pageViewMatchMakerQuestionPage(2, 'Financial preferences');
  }, []);

  return (
    <MatchMakerQuestionWrapper
      currentStep={Step.MM2}
      pageTitle={t('question-pages.question-page-two.title')}
      isEditMode={isEditMode}
      currentPercentage={2}
      nextPage={RouterHelpers.getMatchMakerQuestionPage3Path()}
      previousPage={RouterHelpers.getMatchMakerQuestionPage1Path()}
      skipTo={{
        page: RouterHelpers.getDealerSelectionPagePath(),
        targetJourneyState: JOURNEY_STATE.DEALER_SELECTION,
        skippedPagesData: _.pick(initialState.matchMakerAnswers, [
          'typeOfGears',
          'minPower',
          'maxPower',
          'specialEquipments',
          'financePlan',
          'milesPerYear',
          'additionalServices'
        ])
      }}
      validate={(values) => {
        const validationResult = genericValidator(
          getFieldValidators(t.sieToDu, MM2Fields, values),
          values
        );

        return validationResult;
      }}
    >
      {() => (
        <>
          <Question heading={t('question-pages.items.installments.label')}>
            <ExpectedMonthlyPayment />
          </Question>

          <hr className="u-mv-xlarge"></hr>

          <Question
            heading={t('question-pages.items.additional-deposit.label')}
            infoIcon={
              <InfoIcon
                icon="semantic-info"
                tippyProps={{
                  placement: 'top'
                }}
              >
                <div className="u-text-left u-text-wrap">
                  {t('question-pages.items.additional-deposit.tooltip')}
                </div>
              </InfoIcon>
            }
          >
            <AdditionalDepositInfo />
          </Question>

          <hr className="u-mv-xlarge"></hr>

          <Question heading={t('question-pages.items.duration.label')}>
            <ExpectedDuration />
          </Question>
        </>
      )}
    </MatchMakerQuestionWrapper>
  );
};
