import React, { useState , useEffect } from 'react';


import { useNavigate } from 'react-router-dom';
import { JOURNEY_STATE, useApplicationState } from '../../App.state';
import { adobeDataLayer } from '../../lib/adobe-analytics-data-layer/AdobeDataLayer';

import { loadingOrError } from '../../lib/ui/Loaders/GraphqlLoadingUtility';
import { RouterHelpers } from '../../router/router-helpers';
import { submitAnswers } from '../../api/submitAnswers';
import { Step } from '../../../shared/interface';

import { CustomerInformationComponent } from './CustomerInformationComponent';
import { CustomerContactDetails } from '../../../shared/types';

export function CustomerInformation(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<Error>();
  const { state, dispatch } = useApplicationState();
  const navigate = useNavigate();

  const { matchMakerAnswers, dealer, transactionId, customerInformation } = state;

  useEffect(() => {
    adobeDataLayer.pageViewPersonalInformation();
    // We need to use the empty array to avoid loops
    // eslint-disable-next-line
  }, []);

  if (isLoading || submitError) return loadingOrError(isLoading, submitError);

  const onSubmit = (values: CustomerContactDetails) => {
    if (!values) return;

    setIsLoading(true);

    submitAnswers({
      transactionId,
      matchMakerAnswers,
      selectedDealer: dealer.id,
      contactDetails: values,
      currentStep: Step.CONTACT_DETAILS,
      isEditMode: false
    })
      .then((data) => {
        dispatch({
          type: 'updateState',
          state: { ...state, transactionId: data, customerInformation: values }
        });

        setIsLoading(false);
        adobeDataLayer.setBaseDataPersonalDetails(values);
        dispatch({
          type: 'updateJourneyStatus',
          journeyStatus: JOURNEY_STATE.SUMMARY,
          isEditMode: false
        });
        navigate(RouterHelpers.getSummaryPagePath());
      })
      .catch((e) => {
        setIsLoading(false);
        setSubmitError(e);
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <CustomerInformationComponent
      dealer={dealer}
      onSubmit={onSubmit}
      initialValues={customerInformation}
      goBack={goBack}
    ></CustomerInformationComponent>
  );
}
