import {
  CustomRangeSlider as BronsonReactCustomRangeSlider,
  Layout
} from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MatchMakerAnswers } from '../../../../../shared/types';
import { TrackableComponent } from '../../../../components';
import { kwToPs } from '../../../../utils/kwToPs';
import { MAX_ENGINE_POWER, MIN_ENGINE_POWER } from '../../../helpers/constants';

interface RangeProps {
  values: MatchMakerAnswers;
  setFieldValue: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const RangeSlider: React.FC<RangeProps> = ({ values, setFieldValue }) => {
  const { t } = useTranslation('match-maker');
  const [selectedValue, setSelectedValue] = useState<{
    min: number;
    max: number;
  }>({ min: values.minPower || 0, max: values.maxPower || 0 });

  const handleOnChange = (values: number[]) => {
    setSelectedValue({ min: values[0], max: values[1] });

    setFieldValue('minPower', values[0]);
    setFieldValue('maxPower', values[1]);
  };

  const formatNumber = (value: number): string => {
    return t('question-pages.items.power.power-format', {
      ps: kwToPs(value),
      kw: Math.round(value)
    });
  };

  return (
    <Layout center>
      <Layout.Item default="11/12">
        <TrackableComponent
          trackingComponent="CustomRangeSlider"
          trackingFunction={`sliderPower${selectedValue}`}
        >
          <BronsonReactCustomRangeSlider
            range={{
              min: MIN_ENGINE_POWER,
              max: MAX_ENGINE_POWER
            }}
            value={[values.minPower as number, values.maxPower as number]}
            connect={[false, true, false]}
            step={10}
            tooltips={{
              to: formatNumber
            }}
            onSet={(values: string | number | (string | number)[]) => {
              handleOnChange((values as number[]).map((v) => Math.round(v)));
            }}
          />
        </TrackableComponent>
      </Layout.Item>
    </Layout>
  );
};
