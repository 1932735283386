import { Navigate } from 'react-router-dom';

import { adobeDataLayer } from '../lib/adobe-analytics-data-layer/AdobeDataLayer';
import { RouterHelpers } from './router-helpers';

function EntryPoint(): JSX.Element {
  const entryPoint = (): string => {
    if ((window as any).du_digitalData) {
      adobeDataLayer.interactionEntryPoint();
    }

    return RouterHelpers.getMatchMakerQuestionPage1Path();
  };

  return <Navigate to={entryPoint()} />;
}

export default EntryPoint;
