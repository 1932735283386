import i18n from 'i18next';
import numbro from 'numbro';
import { initReactI18next } from 'react-i18next';
import { merge } from 'lodash';

import base from './resources/localization/translations.de-DE.json';
import chi from './resources/localization/chi.json';
import { appConfig } from './config';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const languages = require('numbro/dist/languages.min.js');

const appLanguage = appConfig.language;

// -------------- load numbro.js settings --------------------
numbro.registerLanguage(languages[appLanguage]);
numbro.setLanguage(appLanguage);

type TranslationFile = {
  [appLang: string]: {
    'common': Record<string, unknown>
    [brands: string]: Record<string, unknown>
  }
}

const loadLocalizationResources = (...jsonFiles: Array<TranslationFile>) => {
  return merge(
    {},
    ...jsonFiles.map(file => {
      return [
        file[appLanguage]['common'],
        file[appLanguage][appConfig.brandName]
      ];
    }).flat()
  );
};

const resources = loadLocalizationResources(base, chi);

// -------------- init i18next --------------------
// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  lng: process.env.REACT_APP_LANG || 'en',
  fallbackLng: 'en',

  resources: {
    [appLanguage]: resources
  },

  // disable debug in production and test
  // disabling debug in testing gives us less chatter
  debug:
    'production' !== process.env.NODE_ENV && 'test' !== process.env.NODE_ENV,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  // handle translation keys that can't be found. if keys has suffix "tooltip",
  // return empty string. otherwise return key. This is to support show/hide
  // tooltip based on its value, at the meantime avoid some weird behaviors like
  // empty validation error message
  parseMissingKeyHandler: (key) => (key.endsWith('tooltip') ? '' : key),

  keySeparator: '.', // we use hierarchically nested translations

  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      if (format === 'currency')
        return value !== null
          ? numbro(value).formatCurrency({
            mantissa: 2,
            thousandSeparated: true,
            optionalMantissa: true,
            currencyPosition: 'postfix',
            spaceSeparated: true,
            spaceSeparatedCurrency: true
          })
          : '';

      if (format === 'number')
        return value !== null
          ? numbro(value).format({ output: 'number', thousandSeparated: true })
          : '';

      if (format === 'percentage')
        return value !== null
          ? numbro(value).format({ output: 'percent', mantissa: 2 })
          : '';

      return value;
    }
  }
});

export default i18n;
