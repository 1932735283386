import React from 'react';
import { Layout, RadioButton } from '@vwfs-bronson/bronson-react';
import { TrackableComponent } from '../../../components';
import { upperFirst } from 'lodash';
import { useFormikContext } from 'formik';
import { MatchMakerAnswers } from '../../../../shared/types';

export const ExpectedDuration: React.FC = () => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext<MatchMakerAnswers>();

  const onChange = (value: number) => {
    return () => {
      setFieldValue('expectedDuration', value);
      setFieldTouched('expectedDuration');
    };
  };

  const durationRadioButtons = [
    {
      inputName: 'terms',
      inputValue: '24',
      checkedIf: values.expectedDuration === 24,
      onChange: onChange(24),
      labelText: '24'
    },
    {
      inputName: 'terms',
      inputValue: '30',
      checkedIf: values.expectedDuration === 30,
      onChange: onChange(30),
      labelText: '30'
    },
    {
      inputName: 'terms',
      inputValue: '36',
      checkedIf: values.expectedDuration === 36,
      onChange: onChange(36),
      labelText: '36'
    },
    {
      inputName: 'terms',
      inputValue: '42',
      checkedIf: values.expectedDuration === 42,
      onChange: onChange(42),
      labelText: '42'
    },
    {
      inputName: 'terms',
      inputValue: '48',
      checkedIf: values.expectedDuration === 48,
      onChange: onChange(48),
      labelText: '48'
    },
    {
      inputName: 'terms',
      inputValue: '54',
      checkedIf: values.expectedDuration === 54,
      onChange: onChange(54),
      labelText: '54'
    },
    {
      inputName: 'terms',
      inputValue: '60',
      checkedIf: values.expectedDuration === 60,
      onChange: onChange(60),
      labelText: '60'
    }
  ];

  return (
    <Layout data-testid={`RadioLayoutGroup-${durationRadioButtons[0].inputName}`}>
      {durationRadioButtons.map((item, index) => (
        <Layout.Item key={`${index}-${item.inputValue}`} default="1/3" xs="1/1">
          <TrackableComponent
            trackingComponent="radiobutton"
            trackingFunction={`radioButton${upperFirst(item.inputName)}${item.inputValue}`}
          >
            <RadioButton
              name={item.inputName}
              value={item.inputValue}
              checked={item.checkedIf}
              onChange={item.onChange}
            >
              {item.labelText}
            </RadioButton>
          </TrackableComponent>
        </Layout.Item>
      ))}
    </Layout>
  );
};
