
import { FormikContextType, FormikErrors, useFormikContext } from 'formik';
import React from 'react';
import { isEqual } from 'lodash';
import { CustomerContactDetails } from '../../../shared/types';

/* Taken from
 * https://github.com/formium/formik/issues/1750
 *
 * Why do we need this?
 * To fire an analytics event in case the customer tries to submit an invalid
 * form we need to do something incase there is an error happening before submit.
 * As described in the issue, right now there is no onError callback in formik.
 *
 * How does this basically work?
 * The component creates an effect that is triggered whenever the formik.errors
 * change. We remember with useState the previous errors.
 *
 * Should the previous errors and the newErrors differ we call the onError callback.
 */
const shouldTriggerErrors = (errors: FormikErrors<CustomerContactDetails>, nextErrors: FormikErrors<CustomerContactDetails>) =>
  !isEqual(errors, nextErrors);

export const FormikErrorListener = ({
  onError
}: { onError: ((formik: FormikContextType<CustomerContactDetails>) => void) }) => {
  const formik = useFormikContext<CustomerContactDetails>();
  const [errors, updateErrors] = React.useState(formik.errors);

  React.useEffect(() => {
    if (shouldTriggerErrors(errors, formik.errors)) {
      onError(formik);
      updateErrors(formik.errors);
    }
  }, [formik.errors]);

  return null;
};
